import { useI18nCommonFe } from "@solvari/translations";
import { arrayWrap } from "@solvari/utils";

import type {
  Decimals,
  PrimitiveOrArrayValue,
} from "@/lib/components/logic/atoms/input/props";

import { truncateDecimals } from "@/lib/helpers/numbers";
import { eager } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules/defineRule";

const number = defineRule({
  name: "number",
  validate: (value: unknown) => {
    return arrayWrap(value).every((value) => !isNaN(Number(value)));
  },
  events: eager,
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.number", messageParams),
});

const decimals = defineRule({
  name: "decimals",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: Decimals,
  ) => {
    if (!value) {
      return true;
    }
    return arrayWrap(value).every(
      (value) =>
        typeof value !== "boolean" &&
        Number(truncateDecimals(value, params)) === Number(value),
    );
  },
  events: eager,
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.decimals", messageParams),
});

const min = defineRule({
  name: "min",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: number,
  ) => {
    value = value || 0;
    return arrayWrap(value).every(
      (value) => typeof value !== "boolean" && Number(value) >= params,
    );
  },
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.min", messageParams),
});

const max = defineRule({
  name: "max",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: number,
  ) => {
    value = value || 0;
    return arrayWrap(value).every(
      (value) => typeof value !== "boolean" && Number(value) <= params,
    );
  },
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.max", messageParams),
});

export { decimals, max, min, number };
