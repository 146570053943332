<template>
  <div
    class="s-address--nl-nl"
    :class="{
      's-address--nl-nl--manual': showManual,
      's-address--nl-nl--autocompleted': showAutocompleteResults,
      [`s-address--nl-nl--size-${props.size}`]: true,
    }"
  >
    <STextInput
      v-model="zipcodeModel"
      autocomplete="postal-code"
      class="s-address--nl-nl__zipcode"
      :label="tr('address_input.zipcode.label')"
      :maxlength="7"
      name="zipcode"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.zipcode.validation.required'),
        }),
        localeSwitchRule(),
        zipcodeRule('nl-NL'),
        zipcodeExistsRule('nl-NL'),
      ]"
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
      @blur="() => attemptAutocomplete()"
    >
      <template #localeSwitch>
        <PMessage
          class="s-error-atom s-address--nl-nl__locale-switch"
          icon="far fa-circle-exclamation"
          :size="size"
        >
          {{ tr("address_input.locale_warning.text") }} <br />
          <PButton
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.locale_warning.button')"
            link
            size="small"
            text
            @click="switchLocale"
          />
        </PMessage>
      </template>
    </STextInput>

    <SCombobox
      v-model="houseNumberModel"
      autocomplete="address-line2"
      class="s-address--nl-nl__house-number"
      disable-filter
      :items="houseNumberSuggestions"
      :label="tr('address_input.house_number.label')"
      :maxlength="255"
      name="houseNumber"
      :open-on-focus="houseNumberExists === false"
      :open-on-input="false"
      :placeholder="tr('address_input.house_number.placeholder')"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.house_number.validation.required'),
        }),
        houseNumberExistsRule(),
      ]"
      show-required-type="none"
      :size="toLegacySize(props.size)"
      :suffix-icon="null"
      :validation-events="onCreatedIfValue(eager)"
      :validation-trigger="houseNumberValidationTrigger"
      v-on="listeners"
    />

    <PMessage
      v-if="showAutocompleteResults"
      class="s-address--nl-nl__autocomplete"
      color="gray"
      icon="far fa-house"
      :size="size"
    >
      {{ streetModel }}, {{ cityModel }}
      <PButton
        class="s-address--nl-nl__autocomplete__button"
        link
        size="small"
        text
        @click="setManual"
      >
        {{ tr("address_input.change_button") }}
      </PButton>
    </PMessage>

    <template v-if="showManual">
      <SCombobox
        v-model="cityModel"
        autocomplete="address-level2"
        class="s-address--nl-nl__city"
        disable-filter
        :items="citySuggestions"
        :label="tr('address_input.city.label')"
        :loading="citySuggestionsFetching"
        :maxlength="255"
        name="city"
        required
        :rules="[
          required(undefined, {
            message: () => tr('address_input.city.validation.required'),
          }),
          cityExistsRule(),
        ]"
        show-required-type="none"
        :size="toLegacySize(props.size)"
        :suffix-icon="null"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />

      <SCombobox
        v-model="streetModel"
        autocomplete="address-line1"
        class="s-address--nl-nl__street"
        disable-filter
        :items="streetSuggestions"
        :label="tr('address_input.street.label')"
        :loading="streetSuggestionsFetching"
        :maxlength="255"
        name="street"
        :placeholder="tr('address_input.street.placeholder')"
        required
        :rules="[
          required(undefined, {
            message: () => tr('address_input.street.validation.required'),
          }),
          streetExistsRule(),
        ]"
        show-required-type="none"
        :size="toLegacySize(props.size)"
        :suffix-icon="null"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ValidateEvent } from "@solvari/common-fe/validation";

import { reEmit, useModel } from "@solvari/common-fe/composables";
import { alphabeticCompare } from "@solvari/common-fe/helpers";
import {
  autocompleteNlNlAddress,
  defineRule,
  eager,
  getCitySuggestions,
  getHouseNumberSuggestions,
  getStreetSuggestions,
  onCreatedIfValue,
  required,
  zipcodeExistsRule,
  zipcodeRule,
} from "@solvari/common-fe/validation";
import { computedAsync, until, watchImmediate } from "@vueuse/core";
import { isString } from "radash";
import { computed, ref } from "vue";

import { SCombobox } from "@solvari/common-fe";
import { type LocaleIso, useI18nAddressInput } from "@solvari/translations";
import { asyncThrottle } from "@solvari/utils";

import { toLegacySize } from "@/helpers/backwardsCompatibility.ts";
import STextInput from "@/molecules/STextInput.vue";
import { PButton, PMessage } from "@/primeVueExports.ts";

import { useLocaleSwitch } from "./useLocaleSwitch";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  city: string | null;
  houseNumber: string | null;
  locale: LocaleIso;
  size?: "large" | "small";
  street: string | null;
  zipcode: string | null;
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string | null): void;
  (event: "update:houseNumber", houseNumber: string | null): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string | null): void;
  (event: "update:zipcode", zipcode: string | null): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const { tr } = useI18nAddressInput();
const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcodeModel = useModel("zipcode", props, emit, { local: true });
const houseNumberModel = useModel("houseNumber", props, emit, { local: true });
const cityModel = useModel("city", props, emit, { local: true });
const streetModel = useModel("street", props, emit, { local: true });

const showAutocompleteResults = ref(false);
const showManual = ref(false);

function setManual() {
  showManual.value = true;
  showAutocompleteResults.value = false;
}
const autocomplete = asyncThrottle(autocompleteNlNlAddress);

async function attemptAutocomplete(zipcode = zipcodeModel.value) {
  if (!zipcode || !zipcodeRule("nl-NL").validate(zipcode)) {
    return;
  }
  const result = await autocomplete({
    zipcode: zipcode.replace(" ", "").toUpperCase(),
  });

  if (result instanceof Error || result === null) {
    setManual();
    return;
  }

  showManual.value = false;
  showAutocompleteResults.value = true;
  zipcodeModel.value = result.zipcode;
  streetModel.value = result.street;
  cityModel.value = result.city;
}
void attemptAutocomplete();

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);

/*
  houseNumber
 */
const suggestHouseNumber = asyncThrottle(getHouseNumberSuggestions);
const houseNumberSuggestionsFetching = ref(false);
const houseNumberExists = ref<boolean | null>(null);

const houseNumberSuggestions = computedAsync(
  () =>
    suggestHouseNumber({
      locale: "nl-NL",
      houseNumber: houseNumberModel.value,
      zipcode: zipcodeModel.value,
    }),
  [],
  { lazy: true, evaluating: houseNumberSuggestionsFetching },
);

const houseNumberExistsRule = defineRule({
  name: "exists",
  validate: async (houseNumber: unknown) => {
    if (!isString(houseNumber) || !houseNumber) {
      return false;
    }
    await until(houseNumberSuggestionsFetching).toBe(false);
    houseNumberExists.value =
      houseNumberSuggestions.value.includes(houseNumber);
    return houseNumberExists.value;
  },
  events: ["blur", "update:zipcode"],
  color: "warning",
  blocking: false,
  message: () => tr("address_input.house_number.validation.exists"),
});

function houseNumberValidationTrigger(validateEvent: ValidateEvent) {
  watchImmediate(zipcodeModel, () => {
    if (houseNumberModel.value) {
      void validateEvent("update:zipcode");
    }
  });
}

/*
  City
 */
const suggestCity = asyncThrottle(getCitySuggestions);
const citySuggestionsFetching = ref(false);

const citySuggestions = computedAsync(
  async () => {
    return showManual.value
      ? await suggestCity({ locale: "nl-NL", city: cityModel.value })
      : [];
  },
  [],
  { lazy: true, evaluating: citySuggestionsFetching },
);

const cityExistsRule = computed(() =>
  defineRule({
    name: "exists",
    validate: async (city) => {
      if (!city || typeof city !== "string") {
        return false;
      }
      await until(citySuggestionsFetching).toBe(false);
      return citySuggestions.value.some((suggestion) =>
        alphabeticCompare(suggestion, city),
      );
    },
    events: onCreatedIfValue(["blur"], zipcodeModel.value && cityModel.value),
    color: "warning",
    blocking: false,
    message: tr("address_input.city.validation.exists"),
  }),
);

/*
  Street
 */
const suggestStreet = asyncThrottle(getStreetSuggestions);

const streetSuggestionsFetching = ref(false);
const streetSuggestions = computedAsync(
  async () => {
    return showManual.value
      ? await suggestStreet({
          locale: "nl-NL",
          city: cityModel.value,
          street: streetModel.value,
        })
      : [];
  },
  [],
  { lazy: true, evaluating: streetSuggestionsFetching },
);

const streetExistsRule = computed(() =>
  defineRule({
    name: "exists",
    validate: async (street: unknown) => {
      if (!street || typeof street !== "string") {
        return false;
      }
      await until(streetSuggestionsFetching).toBe(false);
      return streetSuggestions.value.some((suggestion) =>
        alphabeticCompare(suggestion, street),
      );
    },
    events: onCreatedIfValue(["blur"], zipcodeModel.value && streetModel.value),
    color: "warning",
    blocking: false,
    message: tr("address_input.street.validation.exists"),
  }),
);
</script>

<style lang="postcss">
.s-address--nl-nl {
  @apply grid gap-4;
  grid-template-columns: 2fr minmax(min-content, 1fr);
  grid-template-areas: "zipcode house-number";

  &--autocompleted {
    grid-template-areas:
      "zipcode house-number"
      "autocomplete autocomplete";
  }

  &--manual {
    grid-template-areas:
      "zipcode house-number"
      "city ."
      "street .";
  }

  &--size {
    &-small {
      @apply gap-3;
    }

    &-large {
      @apply gap-5;
    }
  }

  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }

  &__autocomplete {
    @apply justify-self-start;
    grid-area: autocomplete;

    .s-button {
      @apply ml-2;
    }
  }
}
</style>
