type LocaleIso = "fr-BE" | "fr-FR" | "nl-BE" | "nl-NL";
type LocaleMorpheus = "be-FR" | "be-NL" | "fr-FR" | "nl-NL";
type LocaleCountry = "BE" | "FR" | "NL";
type LocaleLanguage = "fr" | "nl";
type LocaleDocument = LocaleIso | LocaleLanguage;
type LocaleAll = LocaleDocument | LocaleIso | LocaleMorpheus;
type LocaleAny = LocaleAll | Lowercase<LocaleAll> | Uppercase<LocaleAll>;

type LocaleFormats = {
  country: LocaleCountry;
  document: LocaleDocument;
  iso: LocaleIso;
  language: LocaleLanguage;
  morpheus: LocaleMorpheus;
};

const localeFormatsMap = Object.freeze([
  {
    iso: "nl-NL",
    document: "nl",
    morpheus: "nl-NL",
    country: "NL",
    language: "nl",
  },
  {
    iso: "nl-BE",
    document: "nl-BE",
    morpheus: "be-NL",
    country: "BE",
    language: "nl",
  },
  {
    iso: "fr-BE",
    document: "fr-BE",
    morpheus: "be-FR",
    country: "BE",
    language: "fr",
  },
  {
    iso: "fr-FR",
    document: "fr",
    morpheus: "fr-FR",
    country: "FR",
    language: "fr",
  },
] satisfies LocaleFormats[]);

function findLocaleFormats(locale: LocaleAny): LocaleFormats {
  return localeFormatsMap.find(({ iso, document, morpheus }) => {
    return !!Object.values({ iso, document, morpheus }).find(
      (localeFormat) => localeFormat.toLowerCase() === locale.toLowerCase(),
    );
  })!;
}

function localeToIso(locale: LocaleAny): LocaleIso {
  return findLocaleFormats(locale).iso;
}

function localeToMorpheus(locale: LocaleAny): LocaleMorpheus {
  return findLocaleFormats(locale).morpheus;
}

function getCountry(locale: LocaleAny): LocaleCountry {
  return findLocaleFormats(locale).country;
}

function getLanguage(locale: LocaleAny): LocaleLanguage {
  return findLocaleFormats(locale).language;
}

export { getCountry, getLanguage, localeToIso, localeToMorpheus };

export type {
  LocaleAny,
  LocaleCountry,
  LocaleDocument,
  LocaleFormats,
  LocaleIso,
  LocaleLanguage,
  LocaleMorpheus,
};
