import { computed } from "vue";

import { propsDefinition } from "@/lib/composables/componentComposable.ts";
import { max, min, number } from "@/lib/validation/rules/number.ts";

type InferNumberInputRulesProps = {
  max?: number;
  min?: number;
};

const inferNumberInputRulesProps = propsDefinition({
  max: { type: Number, required: false },
  min: { type: Number, required: false },
});

function inferNumberInputRules(props: Partial<InferNumberInputRulesProps>) {
  return computed(() => {
    const rules = [];

    rules.unshift(number());

    if (props.max || props.max === 0) {
      rules.unshift(max(props.max));
    }

    if (props.min || props.min === 0) {
      rules.unshift(min(props.min));
    }

    return rules;
  });
}

export type { InferNumberInputRulesProps };
export { inferNumberInputRules, inferNumberInputRulesProps };
