import { nanoid } from "nanoid";
import { type MaybeRefOrGetter, type PropType, type Ref, toValue } from "vue";
import { computed, inject, reactive, ref, toRef, watch } from "vue";

import type { Color } from "@/lib/components/types";
import type {
  EventsCallback,
  ValidationRule,
  ValidationRuleBase,
} from "@/lib/validation/validation.types";

import {
  emitsDefinition,
  propsDefinition,
  propsToDefaults,
} from "@/lib/composables/componentComposable";
import { mergeReactive } from "@/lib/helpers/reactivity";
import { aggressive } from "@/lib/validation/events";
import { required } from "@/lib/validation/rules/native";
import { _validationObserverKey } from "@/lib/validation/ValidationObserver/useValidationObserver";
import { useListeners } from "@/lib/validation/ValidationProvider/useListeners";

import type { ValidateEvent } from "./useValidation";

import { useObserverInject } from "./useObserverInject";
import { useRules } from "./useRules";
import { useValidation } from "./useValidation";

const useValidationProviderScoped = <ModelValue>() =>
  propsDefinition({
    required: { type: Boolean, default: false, required: false },
    errorLabel: { type: String, required: false },
    label: { type: String, required: false },
    name: { type: String, required: true },
    rules: {
      type: Array as PropType<NoInfer<ValidationRuleBase<ModelValue>>[]>,
      default: () => [],
    },
    validationEvents: {
      type: [Array, Function] as PropType<
        NoInfer<EventsCallback<ModelValue>> | string[]
      >,
      default: () => aggressive,
      required: false,
    },
    validationTrigger: {
      type: Function as PropType<(validateEvent: ValidateEvent) => void>,
      default: undefined,
      required: false,
    },
  });

const useValidationProviderProps = useValidationProviderScoped;

const useValidationProviderEmits = emitsDefinition(["validationError"]);

type UseValidationProviderProps<ModelValue> = {
  errorLabel?: string;
  label?: string;
  name: string;
  required?: boolean;
  rules?: ValidationRuleBase<NoInfer<ModelValue>>[];
  validationEvents?: EventsCallback<NoInfer<ModelValue>> | string[];
  validationTrigger?: (validateEvent: ValidateEvent) => void;
};
type UseValidationProviderEmits = (
  event: "validationError",
  value: { error: string | null; name: string },
) => void;

function mergeErrorProps(
  ...errorProps: {
    color?: Color;
    component?: string;
    error: string | null;
  }[]
) {
  return computed(() => errorProps.find(({ error }) => !!error));
}

function getError<ModelValue>(
  failedRule: Ref<ValidationRule<ModelValue> | null>,
  serverSideError: Ref<string>,
) {
  return computed(() => {
    if (!failedRule.value) {
      return serverSideError.value || null;
    }
    if (!failedRule.value.blocking && serverSideError.value) {
      return serverSideError.value;
    }
    return failedRule.value.message;
  });
}

function useValidationProvider<ModelValue>(
  modelValue: MaybeRefOrGetter<ModelValue>,
  options: UseValidationProviderProps<NoInfer<ModelValue>>,
  emit: UseValidationProviderEmits = () => null,
) {
  const props = mergeReactive(
    propsToDefaults(useValidationProviderProps()),
    options,
  );
  const validating = ref(false);

  const failedRule = ref<ValidationRule | null>(null);

  const validationObserver = inject(_validationObserverKey, null);

  const serverSideError = computed(() => {
    return validationObserver?.serverSideErrors.value[props.name]?.[0] ?? "";
  });

  const error = getError(failedRule, serverSideError);

  watch(error, (error) => {
    if (error) {
      emit("validationError", { error, name: props.name });
    }
  });

  const { allRules, blockingRules } = useRules(modelValue, props, error);

  const {
    validatedRules,
    validate,
    validateAll,
    validateEvent,
    validateSilent,
  } = useValidation(modelValue, validating, failedRule, allRules);

  props.validationTrigger?.(validateEvent);

  /*
    State
   */
  const blockingValidated = computed(() => {
    return blockingRules.value.every(({ name }) =>
      validatedRules.value.includes(name),
    );
  });

  const allValidated = computed(() => {
    return validatedRules.value.length === allRules.value.length;
  });

  const hasErrors = computed(() => {
    return !!failedRule.value || !!serverSideError.value;
  });

  const hasBlockingErrors = computed(() => {
    return !!failedRule.value?.blocking || !!serverSideError.value;
  });

  const displayValid = computed(() => {
    return (
      required().validate(toValue(modelValue)) &&
      allValidated.value &&
      !hasErrors.value
    );
  });

  const displayInvalid = computed(() => hasBlockingErrors.value);

  const initiallyValid = ref(false);
  async function updateInitiallyValid() {
    initiallyValid.value = !(await validateSilent(blockingRules.value));
  }
  void updateInitiallyValid();

  const validationPassed = computed(() => {
    return (
      (blockingValidated.value || initiallyValid.value) &&
      !hasBlockingErrors.value
    );
  });

  const validationNotPassed = computed(() => {
    return (
      (!blockingValidated.value && !initiallyValid.value) ||
      hasBlockingErrors.value
    );
  });

  const storedErrorId = nanoid(10);
  const errorId = computed(() => (error.value ? storedErrorId : undefined));

  function reset() {
    validatedRules.value = [];
    failedRule.value = null;
  }

  useObserverInject(
    toRef(() => props.name),
    modelValue,
    validateAll,
    validationPassed,
    validationNotPassed,
    reset,
  );

  const validationListeners = useListeners(modelValue, validateEvent, allRules);

  return {
    valid: displayValid,
    invalid: displayInvalid,
    validating,
    error,
    failedRule,
    errorComponent: toRef(() => failedRule.value?.component),
    allRules,
    errorId,
    errorProps: reactive({
      name: toRef(() => failedRule.value?.name),
      error,
      color: toRef(() => failedRule.value?.color),
      component: toRef(() => failedRule.value?.component),
      // eslint-disable-next-line
      params: toRef<any>(() => failedRule.value?.params),
    }),
    inputProps: reactive({
      valid: displayValid,
      loading: validating,
      invalid: displayInvalid,
      color: toRef(() => failedRule.value?.color),
    }),
    validationListeners,
    validate,
    validateAll,
    validateEvent,
  };
}

export type {
  UseValidationProviderEmits,
  UseValidationProviderProps,
  ValidateEvent,
};
export {
  getError,
  mergeErrorProps,
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderProps,
  useValidationProviderScoped,
};
