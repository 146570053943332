<template>
  <STextInput
    v-model="model"
    class="form-question"
    :label="tr('fields.firstName.label')"
    :maxlength="255"
    name="firstName"
    required
    show-required-type="none"
    v-on="reEmit(emit, ['focus', 'validationError'])"
    @focus="emit('focus', 'firstName')"
  />
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";
import { toRef } from "vue";

import { STextInput } from "@solvari/components";

import { useI18nForms } from "@/plugins/i18n.ts";
import { useLeadStore } from "@/plugins/store/lead";
defineOptions({ inheritAttrs: false });

const { tr } = useI18nForms();

const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const leadStore = useLeadStore();
const model = toRef(leadStore, "firstName");
</script>
