import type { Simplify, TupleToUnion } from "type-fest";

import { pick, unique } from "radash";
import { computed, type ComputedRef, unref, type UnwrapNestedRefs } from "vue";
import { reactive, readonly, toRefs } from "vue";

import type { SpreadObjects } from "@/lib/helpers/types";

function reactivePick<
  ObjectType extends Record<PropertyKey, unknown>,
  Keys extends (keyof ObjectType)[],
>(object: ObjectType, keys: Keys) {
  return reactive(pick(toRefs(object), keys)) as Simplify<
    Pick<ObjectType, TupleToUnion<Keys>>
  >;
}

function mergeReactive<Objects extends Record<PropertyKey, unknown>[]>(
  ...objects: Objects
) {
  const keys = unique(objects.flatMap((object) => Object.keys(object)));
  const merged = keys.reduce<Record<string, ComputedRef<unknown>>>(
    (merged, key) => {
      merged[key] = computed(() => {
        return objects.reduceRight<unknown>(
          (value, object) => value ?? unref(object[key]),
          undefined,
        );
      });

      return merged;
    },
    {},
  );

  return readonly(reactive(merged)) as Simplify<
    UnwrapNestedRefs<Readonly<SpreadObjects<Objects>>>
  >;
}

type ListenerCallback = (event: never) => Promise<void> | void;

type ListenerObject = Record<string, ListenerCallback | ListenerCallback[]>;
function mergeListeners(...listenerObjects: ListenerObject[]) {
  return listenerObjects.reduce(
    (mergedListeners: ListenerObject, listeners: ListenerObject) => {
      Object.entries(listeners).forEach(([listener, callback]) => {
        const existingListeners = mergedListeners[listener];
        if (existingListeners) {
          mergedListeners[listener] = [existingListeners, callback].flat();
        } else {
          mergedListeners[listener] = callback;
        }
      });

      return mergedListeners;
    },
    {},
  );
}

export { mergeListeners, mergeReactive, reactivePick };
