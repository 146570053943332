import { arrayWrap } from "@solvari/utils";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    clarity?: {
      (method: "event", event: string): void;
      (method: "set", key: string, value: string[] | string): void;
    };
    dataLayer?: DataLayerEvent[];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    google_tag_manager?: unknown;
  }
}

type EventPayload = Record<number | string, unknown>;

type DataLayerEvent = EventPayload & {
  event: string;
};

function getDataLayer() {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  return window.dataLayer;
}

type RequiredContainers = (RegExp | string)[] | RegExp | string;

function createGtmEventCallback(
  containers: RequiredContainers,
  resolveEvent: (allContainersTriggered: boolean) => void,
) {
  const triggeredContainers: string[] = [];

  return (containerId: string) => {
    triggeredContainers.push(containerId);
    if (!requiredContainersTriggered(triggeredContainers, containers)) {
      return;
    }
    resolveEvent(true);
  };
}

function requiredContainersTriggered(
  triggeredContainers: string[],
  requiredContainers: RequiredContainers,
) {
  return arrayWrap(requiredContainers).every((requiredContainer) => {
    if (typeof requiredContainer === "string") {
      return triggeredContainers.includes(requiredContainer);
    }

    return !!triggeredContainers.find((triggeredContainer) =>
      requiredContainer.test(triggeredContainer),
    );
  });
}

function submitToClarity(name: string, payload: EventPayload) {
  if (window.clarity) {
    Object.entries(payload).forEach(([key, value]) => {
      window.clarity?.(
        "set",
        key,
        Array.isArray(value) ? value.map(String) : String(value),
      );
    });
    window.clarity("event", name);
  }
}

type EventCallbackOptions = {
  callback?: (allContainersTriggered: boolean, ...args: unknown[]) => void;
  containers?: RequiredContainers;
  timeout?: number;
};

function createGtmEvent(
  name: string,
  payload: EventPayload = {},
  { callback, timeout = 2000, containers = /GTM-/ }: EventCallbackOptions = {},
) {
  const event = {
    ...payload,
    event: name,
  };

  submitToClarity(name, payload);

  if (!window.google_tag_manager) {
    getDataLayer().push(event);
    callback?.(false);
    return false;
  }

  return new Promise<boolean>((resolve) => {
    const resolveEvent = (allContainersTriggered: boolean) => {
      callback?.(allContainersTriggered); // Make it optional to use the promise
      resolve(allContainersTriggered);
    };

    setTimeout(() => resolveEvent(false), timeout);

    getDataLayer().push({
      ...event,
      eventCallback: createGtmEventCallback(containers, resolveEvent),
    });
  });
}

export { createGtmEvent, getDataLayer };
