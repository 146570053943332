<template>
  <BaseInput
    v-bind="props"
    :class="props.class"
    :rules="[...inferredRules, ...(props.rules ?? [])]"
    v-on="
      reEmit(emit, ['update:loading', 'validationError', 'update:modelValue'])
    "
  >
    <template #input="{ id, describedBy, errorId, listeners, invalid }">
      <PInputNumber
        v-bind="$attrs"
        v-model="modelValue"
        :disabled
        fluid
        :input-id="id"
        :invalid
        :max
        :min
        :name
        :pt="
          assign(
            {
              pcInputText: {
                root: {
                  name,
                  ['aria-errormessage']: errorId,
                  ['aria-describedby']: describedBy,
                },
              },
            },
            pt?.pcInput ?? {},
          )
        "
        :required
        :size
        v-on="listeners"
      />
    </template>
    <template v-for="(_index, name) in $slots" #[name]="data">
      <slot :name="name" v-bind="data" />
    </template>
  </BaseInput>
</template>

<script setup lang="ts">
import type {
  InputNumberPassThroughOptions,
  InputNumberProps,
} from "primevue/inputnumber";

import { useModel } from "@solvari/common-fe/composables";
import { reEmit } from "@solvari/common-fe/helpers";
import {
  inferNumberInputRules,
  type InferNumberInputRulesProps,
} from "@solvari/common-fe/validation";
import { assign } from "radash";

import type { BaseInputEmits, BaseInputProps } from "@/molecules/baseInput.ts";

import BaseInput from "@/molecules/BaseInput.vue";
import { PInputNumber } from "@/primeVueExports.ts";

const props = defineProps<
  BaseInputProps<
    number | null | undefined,
    { pcInput?: InputNumberPassThroughOptions }
  > &
    InferNumberInputRulesProps &
    /* @vue-ignore */ Omit<InputNumberProps, "invalid"> & { class?: string }
>();
const emit = defineEmits<
  BaseInputEmits &
    ((event: "update:modelValue", value: number | null | undefined) => void)
>();

const modelValue = useModel("modelValue", props, emit);

const inferredRules = inferNumberInputRules(props);
</script>
