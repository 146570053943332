import Pusher from "pusher-js";

import { getEnv, memoize } from "@solvari/utils";

export const usePusher = memoize(
  () =>
    new Pusher(getEnv().integrations.pusher.key, {
      cluster: getEnv().integrations.pusher.cluster,
    }),
);
