import { type MaybeRefOrGetter, type Ref, toRef, toValue } from "vue";
import { computed, ref, watch, watchEffect } from "vue";

import type { ValidationRule } from "@/lib/validation/validation.types";

import { required } from "@/lib/validation/rules/native";

type ValidateEvent = (event: string) => Promise<void> | undefined;

function useValidation<Value>(
  modelValue: MaybeRefOrGetter<Value>,
  loading: Ref<boolean>,
  failedRule: Ref<ValidationRule<NoInfer<Value>> | null>,
  allRules: Ref<ValidationRule<NoInfer<Value>>[]>,
) {
  const validatedRules = ref<string[]>([]);

  watch(
    toRef(modelValue),
    () => {
      validatedRules.value = [];
    },
    { deep: true },
  );

  const notRequiredAndWithoutValue = computed(() => {
    // This is necessary because most rules will fail is there's no value
    // Even if the field is not required
    return (
      !allRules.value.some((rule) => rule.name === "required") &&
      !required().validate(toValue(modelValue))
    );
  });

  function validateAll() {
    return validate(allRules.value);
  }

  function validateEvent(event: string) {
    const rulesToValidate = allRules.value.filter((rule) => {
      return rule.events.includes(event);
    });
    if (rulesToValidate.length) {
      return validate(rulesToValidate);
    }
    return undefined;
  }

  async function validateSilent(rules: ValidationRule<Value>[]) {
    if (notRequiredAndWithoutValue.value) {
      return null;
    }

    const validationResults = await Promise.all(
      rules.map((rule) => rule.validate(toValue(modelValue))),
    );

    for (let index = 0; index < validationResults.length; index++) {
      if (!validationResults[index]) {
        return rules[index]?.name ?? null;
      }
    }

    return null;
  }

  const failedRuleName = ref<string | null>(null);

  async function validate(rules: ValidationRule<Value>[]) {
    loading.value = true;
    failedRuleName.value = await validateSilent(rules);
    loading.value = false;
    validatedRules.value = [
      ...new Set([...validatedRules.value, ...rules.map(({ name }) => name)]),
    ];
  }

  watchEffect(
    () => {
      failedRule.value =
        allRules.value.find((rule) => rule.name === failedRuleName.value) ??
        null;
    },
    { flush: "sync" },
  );

  return {
    validatedRules,
    validate,
    validateAll,
    validateEvent,
    validateSilent,
  };
}

export type { ValidateEvent };
export { useValidation };
