export const toast = {
  colorScheme: {
    light: {
      info: {
        background: "{primary.100}",
        color: "{primary.700}",
        detailColor: "{primary.700}",
        borderColor: "{primary.200}",
        closeButtonHoverBackground: "{primary.300}",
      },
      error: {
        background: "{danger.100}",
        color: "{danger.700}",
        detailColor: "{danger.700}",
        borderColor: "{danger.200}",
        closeButtonHoverBackground: "{danger.300}",
      },
      secondary: {
        background: "{success.100}",
        color: "{success.700}",
        detailColor: "{success.700}",
        borderColor: "{success.200}",
        closeButtonHoverBackground: "{success.300}",
      },
      success: {
        background: "{success.100}",
        color: "{success.700}",
        detailColor: "{success.700}",
        borderColor: "{success.200}",
        closeButtonHoverBackground: "{success.300}",
      },
      warn: {
        background: "{warning.100}",
        color: "{warning.950}",
        detailColor: "{warning.950}",
        borderColor: "{warning.200}",
        closeButtonHoverBackground: "{warning.300}",
      },
    },
  },
};
