import type { MaybeRefOrGetter } from "vue";

import { computed, toValue } from "vue";

function useSuffixIcon({
  loading,
  validating,
  valid,
  invalid,
  suffixIcon,
  showValidState,
}: {
  invalid?: MaybeRefOrGetter<boolean>;
  loading?: MaybeRefOrGetter<boolean>;
  showValidState?: MaybeRefOrGetter<boolean>;
  suffixIcon?: MaybeRefOrGetter<string | undefined>;
  valid?: MaybeRefOrGetter<boolean>;
  validating?: MaybeRefOrGetter<boolean>;
}) {
  return computed(() => {
    if (toValue(loading) || toValue(validating)) {
      return "fa-regular fa-spinner-third fa-spin";
    }
    if (toValue(suffixIcon)) {
      return toValue(suffixIcon);
    }
    if (toValue(valid) && toValue(showValidState)) {
      return "fa-regular fa-check";
    }
    if (toValue(invalid)) {
      return "fa-regular fa-exclamation-circle";
    }
    return undefined;
  });
}

export { useSuffixIcon };
