import { computed, type PropType } from "vue";

import { propsDefinition } from "@/lib/composables/componentComposable.ts";
import {
  email,
  maxLength,
  minLength,
  patternRule,
  telephone,
} from "@/lib/validation/rules/native.ts";

type InferTextInputRulesProps = {
  maxlength?: number;
  minlength?: number;
  pattern?: RegExp;
  type?: "email" | "password" | "tel" | "text";
};

const inferTextInputRulesProps = propsDefinition({
  type: {
    type: String as PropType<InferTextInputRulesProps["type"]>,
    default: "text",
  },
  pattern: { type: RegExp, required: false },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
});

function inferTextInputRules(props: Partial<InferTextInputRulesProps>) {
  return computed(() => {
    const rules = [];
    if (props.type === "email") {
      rules.push(email());
    }

    if (props.type === "tel") {
      rules.push(telephone());
    }

    if (props.maxlength || props.maxlength === 0) {
      rules.push(maxLength(props.maxlength));
    }

    if (props.minlength || props.minlength === 0) {
      rules.push(minLength(props.minlength));
    }

    if (props.pattern) {
      rules.push(patternRule(props.pattern));
    }

    return rules;
  });
}

export type { InferTextInputRulesProps };
export { inferTextInputRules, inferTextInputRulesProps };
