import { computed } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";

import {
  decimals as decimalsProp,
  type as typeProp,
} from "@/lib/components/logic/atoms/input/props";
import { propsDefinition } from "@/lib/composables/componentComposable";
import {
  decimals,
  email,
  max,
  maxLength,
  min,
  minLength,
  number,
  patternRule,
  telephone,
} from "@/lib/validation";

const inferrableRulesProps = propsDefinition({
  type: typeProp,
  pattern: { type: RegExp, required: false },
  maxlength: { type: Number, required: false },
  minlength: { type: Number, required: false },
  decimals: decimalsProp,
  max: { type: Number, required: false },
  min: { type: Number, required: false },
});

type InferrableRulesProps = DefineProps<typeof inferrableRulesProps>;

function inferTextInputRules(props: Partial<InferrableRulesProps>) {
  function getInferredTextRules(
    textProps: Partial<Pick<InferrableRulesProps, "maxlength" | "minlength">>,
  ) {
    const rules = [];

    if (textProps.maxlength || textProps.maxlength === 0) {
      rules.push(maxLength(textProps.maxlength));
    }

    if (textProps.minlength || textProps.minlength === 0) {
      rules.push(minLength(textProps.minlength));
    }

    return rules;
  }

  function getInferredNumberRules(
    numberProps: Partial<
      Pick<InferrableRulesProps, "decimals" | "max" | "min">
    >,
  ) {
    const rules = [];

    rules.unshift(number());
    rules.unshift(decimals(numberProps.decimals ?? false));

    if (numberProps.max || numberProps.max === 0) {
      rules.unshift(max(numberProps.max));
    }

    if (numberProps.min || numberProps.min === 0) {
      rules.unshift(min(numberProps.min));
    }

    return rules;
  }

  return computed(() => {
    const rules = [];
    if (props.type === "email") {
      rules.push(email());
    }
    if (props.type === "tel") {
      rules.push(telephone());
    }

    if (props.type === "number") {
      rules.push(...getInferredNumberRules(props));
    } else {
      rules.push(...getInferredTextRules(props));
    }

    if (props.pattern) {
      rules.push(patternRule(props.pattern));
    }

    return rules;
  });
}

export { inferrableRulesProps, inferTextInputRules };
