import { isObject } from "radash";

import { useI18nCommonFe } from "@solvari/translations";
import { arrayWrap } from "@solvari/utils";

import type { SFile } from "@/lib/composables/useFileUpload";

import { defineRule } from "@/lib/validation/rules/defineRule";

const isSFile = (value: unknown): value is SFile => {
  return (
    isObject(value) &&
    "uuid" in value &&
    "mimeType" in value &&
    "size" in value &&
    "url" in value
  );
};

const maxSizeRule = defineRule({
  name: "maxSize",
  validate: (value: unknown, size: number) => {
    const arrayValue = arrayWrap(value);
    if (!arrayValue.every(isSFile)) {
      return false;
    }
    return arrayValue.every((file) => file.size <= size * 1000);
  },
  events: ["fileUpload"],
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.max_size", messageParams),
});

const acceptRule = defineRule({
  name: "accept",
  validate: (value: unknown, types: string[] | string) => {
    const arrayValue = arrayWrap(value);
    if (!arrayValue.every(isSFile)) {
      return false;
    }

    const typesArray = arrayWrap(types).flatMap((type) =>
      type.split(",").map((type) => type.trim()),
    );

    const extensions = typesArray.filter((type) => type.startsWith("."));
    const extensionsRegex = new RegExp(`${extensions.join("|")}$`, "i");

    const mimes = typesArray.filter((type) => !type.startsWith("."));
    const mimesRegex = new RegExp(mimes.join("|").replaceAll("*", ".+"));

    return arrayValue.every(
      (file) =>
        (extensions.length && extensionsRegex.test(file.name)) ||
        (mimes.length && mimesRegex.test(file.mimeType)),
    );
  },
  events: ["fileUpload"],
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.accept", messageParams),
});

export { acceptRule, maxSizeRule };
