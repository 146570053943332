<template>
  <legend class="form-header">
    {{ getHeader(props.question.label) ?? props.question.label }}
  </legend>
</template>

<script setup lang="ts">
import type { FormComponentStatic } from "@/helpers/types";

import { useAbTesting } from "@/composables/useAbTesting.ts";

const props = defineProps<{ question: FormComponentStatic }>();
const { getHeader } = useAbTesting();
</script>

<style lang="postcss">
.form-header {
  @apply mb-4 block text-2xl font-normal text-primary-700;
}
</style>
