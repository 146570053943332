import type { ReadonlyDeep } from "type-fest";

type SolvariEnv = ReadonlyDeep<{
  auth: {
    attributionUserId: string | null;
    jwtToken: string | null;
  };
  config: {
    envBE: "acceptance" | "develop" | "local" | "production" | "testing";
    lang: "fr" | "nl";
    localeCode: "be-FR" | "be-NL" | "fr-FR" | "nl-NL";
    localeIso: "fr-BE" | "fr-FR" | "nl-BE" | "nl-NL";
    themeId: number | string | null;
    urlPrefix: "" | "fr" | "nl";
  };
  integrations: {
    algolia: {
      applicationId: string;
      env: "dev" | "prd" | "stg";
      publicSearchApiKey: string;
    };
    pusher: {
      appId: string;
      cluster: string;
      key: string;
    };
  };
  network: {
    admin: string;
    api: string;
    argus: string;
    cdn: string;
    client: {
      base: string;
      baseWithPrefix: string;
    };
    pro: {
      base: string;
      baseWithPrefix: string;
    };
  };
}>;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    solvariEnv?: SolvariEnv;
  }
}

function getEnv(): SolvariEnv {
  if (!window.solvariEnv) {
    throw new Error("Could not find solvariEnv variables on window.");
  }
  return window.solvariEnv;
}

export type { SolvariEnv };
export { getEnv };
