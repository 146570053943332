import { definePreset } from "@primevue/themes";
import Lara from "@primevue/themes/lara";

import { base } from "@/themes/base.ts";
import { button } from "@/themes/button.ts";
import { toast } from "@/themes/toast.ts";

// Emulates the structure of the Lara preset: https://github.com/primefaces/primevue/blob/master/packages/themes/src/presets/lara/index.js
const solvariPreset: unknown = definePreset(Lara, {
  ...base,
  components: {
    button,
    toast,
    galleria: {
      borderWidth: "0",
    },
  },
});

export default solvariPreset;
