import type { DeepReadonly, MaybeRefOrGetter, Ref } from "vue";

import {
  autocompleteNlNlAddress,
  defineRule,
  getZipcodeCitySuggestions,
  onCreatedIfValue,
  zipcodeRule,
} from "@solvari/common-fe/validation";
import { computed, toValue } from "vue";

import type { LocaleIso } from "@solvari/translations";

const alternateLocales = Object.freeze({
  "nl-NL": "nl-BE",
  "nl-BE": "nl-NL",
  "fr-BE": "fr-FR",
  "fr-FR": "fr-BE",
} satisfies Record<LocaleIso, LocaleIso>);

function useLocaleSwitch(
  locale: Ref<LocaleIso>,
  availableLocales?: DeepReadonly<MaybeRefOrGetter<LocaleIso[]>>,
) {
  const alternateLocale = computed(() => alternateLocales[locale.value]);

  function switchLocale() {
    locale.value = alternateLocale.value;
  }

  const localeSwitchRule = defineRule({
    name: "localeSwitch",
    component: "localeSwitch",
    validate: shouldNotSwitchLocale,
    events: onCreatedIfValue(["blur"]),
    color: "primary",
    message: "",
  });

  async function shouldNotSwitchLocale(zipcode: unknown) {
    if (typeof zipcode !== "string") {
      return true;
    }

    if (
      !toValue(availableLocales)?.includes(alternateLocale.value) ||
      zipcodeRule(locale.value).validate(zipcode)
    ) {
      return true;
    }

    if (
      alternateLocale.value === "nl-BE" &&
      zipcodeRule("nl-BE").validate(zipcode)
    ) {
      await tryNlBESwitch(zipcode);
      return false;
    }

    if (
      alternateLocale.value === "nl-NL" &&
      zipcodeRule("nl-NL").validate(zipcode)
    ) {
      await tryNlNLSwitch(zipcode);
      return false;
    }

    if (
      alternateLocale.value === "fr-FR" &&
      zipcodeRule("fr-FR").validate(zipcode)
    ) {
      // No auto switching to fr-FR;
      return false;
    }

    if (
      alternateLocale.value === "fr-BE" &&
      zipcodeRule("fr-BE").validate(zipcode)
    ) {
      // No auto switching to fr-BE;
      return false;
    }

    // Invalid format
    return true;
  }

  async function tryNlBESwitch(zipcode: string) {
    const nlBESuggestions = await getZipcodeCitySuggestions({
      city: "",
      locale: "nl-BE",
      zipcode,
    });

    const isExistingNlBE = nlBESuggestions.some(
      ({ zipcode: zipcodeSuggestion }) => zipcodeSuggestion === zipcode,
    );

    if (isExistingNlBE) {
      switchLocale();
    }
  }

  async function tryNlNLSwitch(zipcode: string) {
    const nlNLAutocomplete = await autocompleteNlNlAddress({
      zipcode,
    });

    if (nlNLAutocomplete) {
      switchLocale();
    }
  }

  return { localeSwitchRule, switchLocale };
}

export { useLocaleSwitch };
