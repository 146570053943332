import type { App } from "vue";

import PrimeVue from "primevue/config";

import solvariPreset from "./themes/solvariPreset";

function initPrimeVueThemed(app: unknown) {
  const vueApp = app as App;
  vueApp.use(PrimeVue, {
    theme: {
      preset: solvariPreset,
      options: {
        darkModeSelector: "none",
      },
    },
  });
}

export { initPrimeVueThemed };
