import type { DeepReadonly, MaybeRefOrGetter } from "vue";

import { nanoid } from "nanoid";
import { mapEntries, sift } from "radash";
import { computed, toValue } from "vue";

import { memoize } from "@solvari/utils";

export function useDescribedBy<
  const Descriptions extends DeepReadonly<
    Record<string, MaybeRefOrGetter<string | null | undefined>>
  >,
>(
  descriptions: Descriptions,
  existingString?: MaybeRefOrGetter<Readonly<string | undefined>>,
) {
  const getId = memoize((_key: keyof Descriptions) => nanoid(10));

  const ids = mapEntries(descriptions, (key: keyof Descriptions, value) => {
    return [key, computed(() => (toValue(value) ? getId(key) : undefined))];
  });

  const describedBy = computed(() => {
    const idString =
      sift(Object.values(ids).map(toValue)).join(" ") || undefined;
    const existingStringValue = toValue(existingString);
    if (idString && existingStringValue) {
      return `${idString} ${existingStringValue}`;
    }
    if (existingStringValue) {
      return existingStringValue;
    }
    return idString;
  });

  return { ids, describedBy };
}
