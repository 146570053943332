import type { Ref } from "vue";

import { reactive } from "vue";

import { useI18n } from "@solvari/translations";

import { useGtmStore } from "@/plugins/store/gtm";

export function useGtmListeners(label?: Ref<string>) {
  const gtmStore = useGtmStore();

  const { trOptional } = useI18n();

  function getLabel(name: string) {
    if (label) {
      return label.value;
    }
    return trOptional(`fields.${name}.label`) ?? name;
  }

  const previousError: Record<string, string | null> = reactive({});

  function onFocus(name: string) {
    void gtmStore.formInteractionEvent({
      name,
      label: getLabel(name),
    });
  }

  function onValidationError({ error, name }: { error: string; name: string }) {
    if (previousError.value === error) {
      return;
    }

    previousError.value = error;

    if (error) {
      void gtmStore.formErrorEvent({
        name,
        label: getLabel(name),
        error,
      });
    }
  }

  return {
    focus: onFocus,
    validationError: onValidationError,
  };
}
