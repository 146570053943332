import {
  captureAbTestingException,
  getAbTestFeature,
} from "@solvari/common-fe/ab-testing";

import type { LocaleIso } from "@solvari/translations";
import { getEnv, singleton } from "@solvari/utils";

const useAbTesting = singleton(() => {
  function getFormVariantId(formId: number | null) {
    const abTestVariantIdMap =
      getAbTestFeature<Record<number, number>>("form-variant-id");

    if (!formId || !abTestVariantIdMap) {
      return null;
    }

    const variantId = abTestVariantIdMap[formId];
    if (!variantId) {
      captureAbTestingException(
        new Error(
          `Tried to run form-variant-id but no variantId was found for formId ${formId}`,
        ),
      );
      return null;
    }

    return variantId;
  }

  function getFormGroupVariantId(formGroupId: number | null) {
    const abTestVariantIdMap = getAbTestFeature<
      Record<number, Partial<Record<LocaleIso, number>>>
    >("form-group-variant-id");

    if (!formGroupId || !abTestVariantIdMap) {
      return null;
    }

    const variantId =
      abTestVariantIdMap[formGroupId]?.[getEnv().config.localeIso];
    if (!variantId) {
      captureAbTestingException(
        new Error(
          `Tried to run form-group-variant-id but no variantId was found for formGroupId ${formGroupId}`,
        ),
      );
      return null;
    }

    return variantId;
  }

  function getFormVariantName(formId: number | null) {
    const abTestVariantNameMap =
      getAbTestFeature<Record<number, string>>("form-variant-name");

    if (!formId || !abTestVariantNameMap) {
      return null;
    }

    const variantId = abTestVariantNameMap[formId];
    if (!variantId) {
      captureAbTestingException(
        new Error(
          `Tried to run form-variant-name but no variantName was found for formId ${formId}`,
        ),
      );
      return null;
    }

    return variantId;
  }

  function getFormGroupVariantName(formGroupId: number | null) {
    const abTestVariantNameMap = getAbTestFeature<Record<number, string>>(
      "form-group-variant-name",
    );

    if (!formGroupId || !abTestVariantNameMap) {
      return null;
    }

    const variantId = abTestVariantNameMap[formGroupId];
    if (!variantId) {
      captureAbTestingException(
        new Error(
          `Tried to run form-group-variant-name but no variantName was found for formGroupId ${formGroupId}`,
        ),
      );
      return null;
    }

    return variantId;
  }

  function getHeader(title: string) {
    const abTestHeaderMap =
      getAbTestFeature<Record<string, string>>("form-header");

    return abTestHeaderMap?.[title] ?? null;
  }

  function getFormId(formId: number | null, formGroupId: number | null) {
    return getFormVariantId(formId) ?? getFormGroupVariantId(formGroupId);
  }

  function getVariantName(formId: number | null, formGroupId: number | null) {
    return getFormVariantName(formId) ?? getFormGroupVariantName(formGroupId);
  }

  return Object.freeze({
    exitIntent: getAbTestFeature<boolean>("forms-exit-intent"),
    reviewStars: getAbTestFeature<boolean>("forms-review-stars"),
    crossSell: getAbTestFeature<boolean>("forms-cross-sell"),
    translations:
      getAbTestFeature<Record<string, unknown>>("forms-translations"),
    getHeader,
    getFormId,
    getFormGroupId: getFormGroupVariantId,
    getVariantName,
  });
});

export { useAbTesting };
