export const button = {
  colorScheme: {
    light: {
      primary: {
        background: "{primary.700}",
        focusShadow: "{primary.100}",
        borderColor: "{primary.700}",
        hoverBorderColor: "{primary.600}",
        hoverBackground: "{primary.600}",
        activeBorderColor: "{primary.500}",
        activeBackground: "{primary.500}",
      },
      secondary: {
        background: "{success.500}",
        color: "#ffffff",
        hoverColor: "#ffffff",
        activeColor: "#ffffff",
        focusShadow: "{success.100}",
        borderColor: "{success.500}",
        hoverBorderColor: "{success.600}",
        hoverBackground: "{success.600}",
        activeBorderColor: "{success.700}",
        activeBackground: "{success.700}",
      },
      success: {
        background: "{success.500}",
        focusShadow: "{success.100}",
        borderColor: "{success.500}",
        hoverBorderColor: "{success.600}",
        hoverBackground: "{success.600}",
        activeBorderColor: "{success.700}",
        activeBackground: "{success.700}",
      },
      warn: {
        background: "{warning.500}",
        focusShadow: "{warning.100}",
        borderColor: "{warning.500}",
        hoverBorderColor: "{warning.600}",
        hoverBackground: "{warning.600}",
        activeBorderColor: "{warning.700}",
        activeBackground: "{warning.700}",
      },
      danger: {
        background: "{danger.500}",
        focusShadow: "{danger.100}",
        borderColor: "{danger.500}",
        hoverBorderColor: "{danger.600}",
        hoverBackground: "{danger.600}",
        activeBorderColor: "{danger.700}",
        activeBackground: "{danger.700}",
      },
      contrast: {
        background: "{gray.500}",
        focusShadow: "{gray.100}",
        borderColor: "{gray.500}",
        hoverBorderColor: "{gray.600}",
        hoverBackground: "{gray.600}",
        activeBorderColor: "{gray.700}",
        activeBackground: "{gray.700}",
      },
    },
  },
};
