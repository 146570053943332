<template>
  <div>
    <div
      ref="rootElement"
      class="s-form"
      :class="{ 's-form--highlighted': highlighted }"
      :data-debug="debugInfo"
      data-tool="solvari-form"
    >
      <FormLoading v-if="formStore.formGetPending" />
      <FormLoadingError v-if="formStore.formGetError" />

      <form
        v-if="formStore.formGetResult"
        :aria-label="formStore.name"
        novalidate
        @submit.prevent="onFormSubmit"
      >
        <FormStepper />

        <FormStep />

        <FormSubmitError v-if="applicationStore.submitError" />
        <FormSubmitSuccess />

        <FormButtons />
      </form>
    </div>
    <STransition duration="200" name="fade">
      <div v-if="highlighted" class="s-form__overlay" />
    </STransition>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

import { emitScrollIntoView } from "@solvari/common-fe/helpers";
import { useValidationObserver } from "@solvari/common-fe/validation";
import { computed, reactive, ref, watchEffect } from "vue";

import { STransition } from "@solvari/common-fe";
import type { LocaleIso } from "@solvari/translations";

import type { ApplicationType, OriginSource } from "@/helpers/types";
import type { FeatureFlags } from "@/plugins/store/featureFlags";

import FormStep from "@/components/FormStep.vue";
import FormSubmitSuccess from "@/components/meta/FormSubmitSuccess.vue";
import { useHighlightOnFocus } from "@/composables/useHighlightOnFocus";
import { useApplicationStore } from "@/plugins/store/application";
import { initFeatureFlagStore } from "@/plugins/store/featureFlags";
import { useFormStore } from "@/plugins/store/form";
import { useGtmStore } from "@/plugins/store/gtm";
import { useLeadStore } from "@/plugins/store/lead";
import { useMetaStore } from "@/plugins/store/meta";
import { useStepperStore } from "@/plugins/store/stepper";

import FormButtons from "./meta/FormButtons.vue";
import FormLoading from "./meta/FormLoading.vue";
import FormLoadingError from "./meta/FormLoadingError.vue";
import FormStepper from "./meta/FormStepper.vue";
import FormSubmitError from "./meta/FormSubmitError.vue";

const props = defineProps({
  locale: { type: String as PropType<LocaleIso>, required: true },
  customerId: { type: String as PropType<string | null>, default: null },
  brandId: { type: String as PropType<string | null>, default: null },
  formGroupId: { type: Number as PropType<number | null>, default: null },
  formId: { type: Number as PropType<number | null>, default: null },
  revisionId: { type: Number as PropType<number | null>, default: null },
  formVariantName: {
    type: String as PropType<string | undefined>,
    default: undefined,
  },
  originSource: { type: String as PropType<OriginSource>, default: "SOLVARI" },
  applicationType: {
    type: String as PropType<ApplicationType | null>,
    default: null,
  },
  satelliteId: { type: Number as PropType<number | null>, default: null },
  zipcode: { type: String as PropType<string | null>, default: null },
  houseNumber: { type: String as PropType<string | null>, default: null },
  city: { type: String as PropType<string | null>, default: null },
  street: { type: String as PropType<string | null>, default: null },
  featureFlags: { type: Object as PropType<FeatureFlags>, default: () => ({}) },
  affiliateId: { type: Number as PropType<number | null>, default: null },
  campaignId: { type: Number as PropType<number | null>, default: null },
  iframeId: { type: Number as PropType<number | null>, default: null },

  translations: {
    type: Object as PropType<Record<string, unknown> | null>,
    default: null,
  },
});

const applicationStore = useApplicationStore();
const formStore = useFormStore();
const gtmStore = useGtmStore();
const metaStore = useMetaStore();
const stepperStore = useStepperStore();

applicationStore.initStoreWatchers(props);
formStore.initStoreWatchers(props);
useLeadStore().initStoreWatchers(props);
initFeatureFlagStore(props.featureFlags);
stepperStore.initStoreWatchers();

const rootElement = ref<HTMLElement | null>(null);
metaStore.initStoreWatchers(
  reactive({ rootElement, translations: props.translations }),
);

const highlighted = useHighlightOnFocus();

const { validateAll } = useValidationObserver();

const debugInfo = computed(() =>
  JSON.stringify({
    props: formStore.formGetProps,
    result: {
      formId: formStore.formId,
      revisionId: formStore.revisionId,
      locale: formStore.locale,
      availableLocales: formStore.availableLocales,
    },
  }),
);

watchEffect(() => {
  if (!formStore.formGetPending && metaStore.isVisible) {
    void gtmStore.formViewEvent();
  }
});

function goToNextStep() {
  if (applicationStore.isIframeApplicationType) {
    emitScrollIntoView(metaStore.rootElement!);
  } else {
    metaStore.rootElement!.scrollIntoView(true);
  }
  stepperStore.goToNextStep();
}

function handleValidationError() {
  const errorContainer =
    rootElement.value!.querySelector(".s-error-atom")?.parentElement ??
    rootElement.value!.querySelector(".s-input--invalid");
  errorContainer?.scrollIntoView(true);
}

async function onFormSubmit() {
  if (!(await validateAll())) {
    handleValidationError();
    return;
  }

  if (stepperStore.nextStep) {
    goToNextStep();
    return;
  }

  applicationStore.submitPending = true;
  await applicationStore.submitApplication();
}
</script>

<style lang="postcss">
.s-form {
  @apply relative z-0 bg-transparent pt-3 font-primary;
  scroll-margin-top: 2rem;

  @screen 400 {
    @apply pt-6;
  }

  --transition-duration: 200ms;
  transition:
    box-shadow var(--transition-duration) 0ms,
    z-index 0ms var(--transition-duration),
    padding 0ms var(--transition-duration),
    margin 0ms var(--transition-duration),
    border-radius 0ms var(--transition-duration),
    background-color 0ms var(--transition-duration);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &--highlighted {
    @apply z-40 -mx-3 -mb-3 rounded bg-white p-3 shadow;
    transition: box-shadow var(--transition-duration);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    @screen 400 {
      @apply -mx-6 -mb-6 p-6;
    }
  }

  &__overlay {
    @apply fixed inset-0 z-30;
    background-color: rgba(0, 0, 0, 0.5);
    scrollbar-gutter: stable;
  }

  &-submit-success,
  &-submit-error {
    @apply mb-6;
  }
}
</style>
