function awaitTimeout(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

function arrayWrap<Value>(value: Value) {
  if (Array.isArray(value)) {
    return value as Extract<Value, unknown[] | readonly unknown[]>;
  }
  return [value] as Exclude<
    Exclude<Value, unknown[] | readonly unknown[]>[],
    never[]
  >;
}

export * from "./deepToString.ts";
export * from "./isEqual.ts";
export * from "./memoize.ts";
export * from "./mergeDeep.ts";
export * from "./solvariEnv.ts";
export * from "./solvariEnvTestDefault.ts";
export * from "./throttleable.ts";
export { arrayWrap, awaitTimeout };
