<template>
  <div
    class="s-address--fr-fr"
    :class="[`s-address--fr-fr--size-${props.size}`]"
  >
    <STextInput
      v-model="zipcodeModel"
      autocomplete="postal-code"
      class="s-address--fr-fr__zipcode"
      :label="tr('address_input.zipcode.label')"
      :maxlength="5"
      name="zipcode"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.zipcode.validation.required'),
        }),
        localeSwitchRule(),
        zipcodeRule('fr-FR'),
      ]"
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    >
      <template #localeSwitch>
        <PMessage
          class="s-address--fr-fr__locale-switch"
          color="primary"
          icon="far fa-circle-exclamation"
          :size="size"
        >
          {{ tr("address_input.locale_warning.text") }} <br />
          <PButton
            action-type="link"
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.locale_warning.button')"
            text
            @click="switchLocale"
          />
        </PMessage>
      </template>
    </STextInput>

    <STextInput
      v-model="cityModel"
      autocomplete="address-level2"
      class="s-address--fr-fr__city"
      :label="tr('address_input.city.label')"
      :maxlength="255"
      name="city"
      required
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />

    <STextInput
      v-model="streetModel"
      autocomplete="address-level1"
      class="s-address--fr-fr__street"
      :label="tr('address_input.street.label')"
      :maxlength="255"
      name="street"
      :placeholder="tr('address_input.street.placeholder')"
      required
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />

    <STextInput
      v-model="houseNumberModel"
      autocomplete="address-level2"
      class="s-address--fr-fr__house-number"
      :label="tr('address_input.house_number.label')"
      :maxlength="255"
      name="houseNumber"
      :placeholder="tr('address_input.house_number.placeholder')"
      required
      show-required-type="none"
      :size="props.size"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
    />
  </div>
</template>

<script setup lang="ts">
import { useModel } from "@solvari/common-fe/composables";
import { reEmit } from "@solvari/common-fe/helpers";
import {
  eager,
  onCreatedIfValue,
  required,
  zipcodeRule,
} from "@solvari/common-fe/validation";

import { type LocaleIso, useI18nAddressInput } from "@solvari/translations";

import STextInput from "@/molecules/STextInput.vue";
import { useLocaleSwitch } from "@/organisms/address/useLocaleSwitch.ts";
import { PButton, PMessage } from "@/primeVueExports.ts";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  city: string | null;
  houseNumber: string | null;
  locale: LocaleIso;
  size?: "large" | "small";
  street: string | null;
  zipcode: string | null;
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string | null): void;
  (event: "update:houseNumber", houseNumber: string | null): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string | null): void;
  (event: "update:zipcode", zipcode: string | null): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const { tr } = useI18nAddressInput();
const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcodeModel = useModel("zipcode", props, emit);
const houseNumberModel = useModel("houseNumber", props, emit);
const cityModel = useModel("city", props, emit);
const streetModel = useModel("street", props, emit);

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);
</script>

<style lang="postcss">
.s-address--fr-fr {
  @apply grid gap-4;
  grid-template-areas:
    "zipcode city city"
    "street street house-number";
  grid-template-columns: minmax(min-content, 1fr) 1fr minmax(min-content, 1fr);

  &--size {
    &-small {
      @apply gap-3;
    }

    &-large {
      @apply gap-5;
    }
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__zipcode-city {
    grid-area: zipcode-city;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }

  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }
}
</style>
