import { isObject, mapValues } from "radash";

import type {
  ValidationRuleBase,
  ValidationRuleDefinition,
  ValidationRuleOverrides,
} from "@/lib/validation/validation.types";

function paramsToMessageParams<ModelValue, Params>({
  label,
  modelValue,
  params,
  name,
}: {
  label: string;
  modelValue: NoInfer<ModelValue>;
  name: string;
  params: Params;
}) {
  const baseParams = { _field_: label, modelValue: String(modelValue) };
  if (!params) {
    return baseParams;
  }
  if (isObject(params)) {
    return { ...baseParams, ...mapValues(params, String) };
  }
  return { ...baseParams, [name]: String(params) };
}

function getMessageFunction<ModelValue, Params>(
  rule: ValidationRuleDefinition<ModelValue, Params>,
  params: Params,
): ValidationRuleBase<ModelValue, Params>["message"] {
  const message = rule.message;

  return (label: string, modelValue: NoInfer<ModelValue>) => {
    if (typeof message !== "function") {
      return message;
    }
    return message({
      modelValue,
      params,
      messageParams: paramsToMessageParams({
        label,
        modelValue,
        name: rule.name,
        params,
      }),
    });
  };
}

function defineRule<
  ModelValue,
  Params,
  Return extends Promise<boolean> | boolean,
>(rule: ValidationRuleDefinition<ModelValue, Params, Return>) {
  return (
    params?: Params,
    overrides?: ValidationRuleOverrides<ModelValue, Params>,
  ): ValidationRuleBase<ModelValue, Params, Return> => {
    const finalRule: ValidationRuleDefinition<ModelValue, Params, Return> =
      overrides ? { ...rule, ...overrides } : rule;

    return {
      ...finalRule,
      validate: (value: ModelValue) => rule.validate(value, params!),
      message: getMessageFunction(finalRule, params!),
      params: params!,
    };
  };
}

export { defineRule, paramsToMessageParams };
