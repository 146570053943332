import type { LocaleIso } from "@solvari/translations";

import type { OriginSource } from "@/helpers/types";
import type { FeatureFlags } from "@/plugins/store/featureFlags";

type DatasetProps = {
  affiliateId?: number;
  applicationType?: string;
  brandId?: string;
  campaignId?: number;
  customerId?: string;
  featureFlags?: FeatureFlags;

  formGroupId?: number;

  formId?: number;
  iframeId?: number;
  locale?: LocaleIso;
  originSource: OriginSource;

  revisionId?: number;

  satelliteId?: number;
};

function getRootElement() {
  const rootElement = document.querySelector<HTMLElement>(
    `[data-tool='solvari-form']`,
  );

  if (!rootElement) {
    throw new Error("Tried to render forms but no root element was found");
  }

  return rootElement;
}

function getProps(rootElement: HTMLElement) {
  return JSON.parse(rootElement.dataset.props!) as DatasetProps;
}

export { getProps, getRootElement };

export type { DatasetProps };
