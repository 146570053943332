<template>
  <div
    class="s-address--be"
    :class="{
      's-address--be--manual': showManual,
      [`s-address--be--size-${props.size}`]: true,
    }"
  >
    <SCombobox
      v-if="!showManual"
      v-model="zipcodeCity"
      autocomplete="postal-code"
      class="s-address--be__zipcode-city"
      disable-filter
      first-item-always-active
      :items="zipcodeCitySuggestions"
      :label="tr('address_input.zipcode_city.label')"
      :loading="zipcodeCitySuggestionsFetching"
      :maxlength="255"
      name="zipcodeCity"
      :placeholder="tr('address_input.zipcode_city.placeholder')"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.zipcode_city.validation.required'),
        }),
        localeSwitchRule(),
        patternRule(
          [zipcodeRegex[locale], /^\D+$/iu, /^[1-9][0-9]{3} - \D+$/iu],
          { message: () => tr('address_input.zipcode_city.validation.regex') },
        ),
        zipcodeCityNlBeExistsRule(locale),
      ]"
      show-required-type="none"
      :size="toLegacySize(props.size)"
      :suffix-icon="null"
      :validation-events="onCreatedIfValue(eager)"
      v-on="listeners"
      @blur="attemptApplyValidSuggestion"
    >
      <template #manualToggle>
        <PMessage class="s-address--be__manual" :size="size">
          {{ tr("address_input.show_manual.text") }} <br />
          <PButton
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.show_manual.button')"
            link
            size="small"
            text
            @click="showManual = true"
          />
        </PMessage>
      </template>

      <template #localeSwitch>
        <PMessage
          class="s-address--be__locale-switch"
          icon="far fa-circle-exclamation"
          :size="size"
        >
          {{ tr("address_input.locale_warning.text") }} <br />
          <PButton
            icon="far fa-chevron-right"
            icon-pos="right"
            :label="tr('address_input.locale_warning.button')"
            link
            size="small"
            text
            @click="switchLocale"
          />
        </PMessage>
      </template>
    </SCombobox>

    <template v-else>
      <STextInput
        v-model="zipcodeModel"
        autocomplete="postal-code"
        class="s-address--be__zipcode"
        :label="tr('address_input.zipcode.label')"
        :maxlength="255"
        name="zipcode"
        required
        :rules="[
          required(undefined, {
            message: () => tr('address_input.zipcode.validation.required'),
          }),
          zipcodeRule(locale),
        ]"
        show-required-type="none"
        :size="props.size"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />

      <STextInput
        v-model="cityModel"
        autocomplete="address-level2"
        class="s-address--be__city"
        :label="tr('address_input.city.label')"
        :maxlength="255"
        name="city"
        required
        :rules="[
          required(undefined, {
            message: () => tr('address_input.city.validation.required'),
          }),
        ]"
        show-required-type="none"
        :size="props.size"
        :validation-events="onCreatedIfValue(eager)"
        v-on="listeners"
      />
    </template>

    <SCombobox
      v-model="streetModel"
      autocomplete="address-line1"
      class="s-address--be__street"
      disable-filter
      :items="streetSuggestions"
      :label="tr('address_input.street.label')"
      :loading="streetSuggestionsFetching"
      :maxlength="255"
      name="street"
      :placeholder="tr('address_input.street.placeholder')"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.street.validation.required'),
        }),
        streetExistsRule(),
      ]"
      show-required-type="none"
      :size="toLegacySize(props.size)"
      :suffix-icon="null"
      :validation-events="onCreatedIfValue(eager)"
      :validation-trigger="streetValidationTrigger"
      v-on="listeners"
    />

    <STextInput
      v-model="houseNumberModel"
      autocomplete="address-line2"
      class="s-address--be__house-number"
      :label="tr('address_input.house_number.label')"
      :maxlength="255"
      name="houseNumber"
      required
      :rules="[
        required(undefined, {
          message: () => tr('address_input.house_number.validation.required'),
        }),
        streetExistsRule(),
      ]"
      show-required-type="none"
      :size="props.size"
      v-on="listeners"
    />
  </div>
</template>

<script setup lang="ts">
import type { ValidateEvent } from "@solvari/common-fe/validation";

import { reEmit, useModel } from "@solvari/common-fe/composables";
import { alphabeticCompare } from "@solvari/common-fe/helpers";
import {
  defineRule,
  eager,
  getStreetSuggestions,
  getZipcodeCitySuggestions,
  onCreatedIfValue,
  patternRule,
  required,
  splitZipcodeCity,
  zipcodeCityNlBeExistsRule,
  zipcodeRegex,
  zipcodeRule,
} from "@solvari/common-fe/validation";
import { computedAsync, until, watchImmediate } from "@vueuse/core";
import { ref, watch, watchEffect } from "vue";

import { SCombobox } from "@solvari/common-fe";
import type { LocaleIso } from "@solvari/translations";
import { useI18nAddressInput } from "@solvari/translations";
import { asyncThrottle } from "@solvari/utils";

import { toLegacySize } from "@/helpers/backwardsCompatibility.ts";
import STextInput from "@/molecules/STextInput.vue";
import { PButton, PMessage } from "@/primeVueExports.ts";

import { useLocaleSwitch } from "./useLocaleSwitch";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  city: string | null;
  houseNumber: string | null;
  locale: LocaleIso;
  size?: "large" | "small";
  street: string | null;
  zipcode: string | null;
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string | null): void;
  (event: "update:houseNumber", houseNumber: string | null): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string | null): void;
  (event: "update:zipcode", zipcode: string | null): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const { tr } = useI18nAddressInput();
const listeners = reEmit(emit, ["focus", "blur", "validationError"]);

const zipcodeModel = useModel("zipcode", props, emit, { local: true });
const houseNumberModel = useModel("houseNumber", props, emit, { local: true });
const cityModel = useModel("city", props, emit, { local: true });
const streetModel = useModel("street", props, emit, { local: true });

const showManual = ref(false);

const { localeSwitchRule, switchLocale } = useLocaleSwitch(
  useModel("locale", props, emit),
  props.availableLocales,
);

/*
  ZipcodeCity
 */
const zipcodeCity = ref("");
watch(zipcodeCity, (zipcodeCity) => {
  const { zipcode, city } = splitZipcodeCity(zipcodeCity);

  zipcodeModel.value = zipcode || "";
  cityModel.value = city;
});

watchEffect(() => {
  if (zipcodeModel.value && cityModel.value) {
    zipcodeCity.value = `${zipcodeModel.value} - ${cityModel.value}`;
    return;
  }
  if (zipcodeModel.value) {
    zipcodeCity.value = zipcodeModel.value;
  }
  if (cityModel.value) {
    zipcodeCity.value = cityModel.value;
  }
});

const suggestZipcodeCity = asyncThrottle(getZipcodeCitySuggestions);

const zipcodeCitySuggestionsFetching = ref(false);
const zipcodeCitySuggestions = computedAsync(
  async () => {
    const suggestions = await suggestZipcodeCity({
      city: cityModel.value,
      locale: props.locale,
      zipcode: zipcodeModel.value,
    });
    return suggestions.map(({ zipcode, city }) => {
      const zipcodeCity = `${zipcode} - ${city}`;
      return {
        value: zipcodeCity,
        label: zipcodeCity,
        zipcode,
        city,
      };
    });
  },
  [],
  {
    evaluating: zipcodeCitySuggestionsFetching,
    onError: () => {
      showManual.value = true;
    },
  },
);

async function attemptApplyValidSuggestion() {
  const suggestions = await suggestZipcodeCity({
    city: cityModel.value,
    locale: props.locale,
    zipcode: zipcodeModel.value,
  });

  const validSuggestion = suggestions.find(
    (suggestion) =>
      suggestion.zipcode === zipcodeModel.value ||
      (cityModel.value && alphabeticCompare(suggestion.city, cityModel.value)),
  );

  if (validSuggestion) {
    zipcodeCity.value = `${validSuggestion.zipcode} - ${validSuggestion.city}`;
  }
}
void attemptApplyValidSuggestion();

/*
  Street
 */
const suggestStreet = asyncThrottle(getStreetSuggestions);

const streetSuggestionsFetching = ref(false);
const streetSuggestions = computedAsync(
  async () => {
    return !showManual.value
      ? await suggestStreet({
          locale: props.locale as "fr-BE" | "nl-BE",
          zipcode: zipcodeModel.value,
          street: streetModel.value,
        })
      : [];
  },
  [],
  { lazy: true, evaluating: streetSuggestionsFetching },
);

const streetExistsRule = defineRule({
  name: "exists",
  validate: async (street: unknown) => {
    if (!street || typeof street !== "string") {
      return false;
    }
    await until(streetSuggestionsFetching).toBe(false);
    return (
      showManual.value ||
      streetSuggestions.value.some((suggestion) =>
        alphabeticCompare(suggestion, street),
      )
    );
  },
  events: ["blur", "update:zipcode", "update:showManual"],
  color: "warning",
  blocking: false,
  message: () => tr("address_input.street.validation.exists"),
});

function streetValidationTrigger(validateEvent: ValidateEvent) {
  watchImmediate(zipcodeModel, () => {
    if (streetModel.value) {
      void validateEvent("update:zipcode");
    }
  });

  watchImmediate(showManual, () => {
    if (streetModel.value) {
      void validateEvent("update:showManual");
    }
  });
}
</script>

<style lang="postcss">
.s-address--be {
  @apply grid gap-4;
  grid-template-areas:
    "zipcode-city zipcode-city zipcode-city"
    "street street house-number";
  grid-template-columns: minmax(min-content, 1fr) 1fr minmax(min-content, 1fr);

  &__manual,
  &__locale-switch {
    @apply mt-4;

    .s-button {
      @apply mt-1;
    }
  }

  &--manual {
    grid-template-areas:
      "zipcode city city"
      "street street house-number";
  }

  &--size {
    &-small {
      @apply gap-3;
    }

    &-large {
      @apply gap-5;
    }
  }

  &__zipcode-city {
    grid-area: zipcode-city;
  }

  &__zipcode {
    grid-area: zipcode;
  }

  &__city {
    grid-area: city;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }
}
</style>
