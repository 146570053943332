<template>
  <SAddressBe
    v-if="props.locale === 'nl-BE' || props.locale === 'fr-BE'"
    v-bind="props"
    v-on="addressOn"
  />

  <SAddressFrFr
    v-else-if="props.locale === 'fr-FR'"
    v-bind="props"
    v-on="addressOn"
  />

  <SAddressNlNl
    v-else-if="props.locale === 'nl-NL'"
    v-bind="props"
    v-on="addressOn"
  />
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";

import type { LocaleIso } from "@solvari/translations";

import SAddressBe from "./SAddressBe.vue";
import SAddressFrFr from "./SAddressFrFr.vue";
import SAddressNlNl from "./SAddressNlNl.vue";

const props = defineProps<{
  availableLocales?: readonly LocaleIso[];
  city: string | null;
  houseNumber: string | null;
  locale: LocaleIso;
  size?: "large" | "small";
  street: string | null;
  zipcode: string | null;
}>();

const emit = defineEmits<{
  (event: "blur" | "focus", name: string): void;
  (event: "update:city", city: string): void;
  (event: "update:houseNumber", houseNumber: string): void;
  (event: "update:locale", locale: LocaleIso): void;
  (event: "update:street", street: string): void;
  (event: "update:zipcode", zipcode: string): void;
  (event: "validationError", error: { error: string; name: string }): void;
}>();

const addressOn = reEmit(emit, [
  "update:locale",
  "update:zipcode",
  "update:houseNumber",
  "update:street",
  "update:city",
  "focus",
  "blur",
  "validationError",
]);
</script>
