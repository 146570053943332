import { type ComputedRef, type MaybeRefOrGetter, toRef } from "vue";
import { computed, watch } from "vue";

import type { ValidationRule } from "@/lib/validation/validation.types";

import type { ValidateEvent } from "./useValidation";

function useListeners<ModelValue>(
  modelValue: MaybeRefOrGetter<ModelValue>,
  validateEvent: ValidateEvent,
  allRules: ComputedRef<ValidationRule<NoInfer<ModelValue>>[]>,
) {
  const flatValidationEvents = computed(() => {
    return [...new Set(allRules.value.flatMap(({ events }) => events))];
  });

  const hasModelValueEventValidation = computed(() => {
    return (
      flatValidationEvents.value.includes("input") ||
      flatValidationEvents.value.includes("update:modelValue")
    );
  });

  const validationListeners = computed<
    Record<string, () => Promise<void> | undefined>
  >(() => {
    return Object.fromEntries(
      flatValidationEvents.value
        .filter(
          (eventName: string) =>
            !["input", "update:modelValue", "created"].includes(eventName),
        )
        .map((eventName: string) => [
          eventName,
          () => validateEvent(eventName),
        ]),
    );
  });

  watch(
    toRef(modelValue),
    () => {
      if (hasModelValueEventValidation.value) {
        void validateEvent("update:modelValue");
      }
    },
    { deep: true },
  );

  void validateEvent("created");

  return validationListeners;
}

export { useListeners };
