import type { DeepReadonly, Ref } from "vue";

import { computed } from "vue";

import { arrayWrap } from "@solvari/utils";

import type { PrimitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";
import type {
  OptionItem,
  OptionValue,
  useOptionsStore,
} from "@/lib/composables/useOptionsStore/useOptionsStore";

function useActiveItem(
  modelValue: Readonly<Ref<PrimitiveOrArrayValue>>,
  activeItemValue: Ref<OptionValue | null>,
  items: DeepReadonly<Ref<OptionItem[]>>,
  findItemIndex: ReturnType<typeof useOptionsStore>["findItemIndex"],
) {
  function normalizeItem(
    item: DeepReadonly<OptionItem>,
  ): DeepReadonly<OptionItem[]> {
    return item.children && item.isOpen
      ? [item, ...item.children.map(normalizeItem).flat()]
      : [item];
  }

  const normalizedItems = computed(() => {
    return items.value.reduce<DeepReadonly<OptionItem[]>>(
      (normalized, item) => {
        return normalized.concat(normalizeItem(item));
      },
      [],
    );
  });

  const activeItem = computed(() =>
    normalizedItems.value.find(({ value }) => value === activeItemValue.value),
  );
  const activeDescendantId = computed(() => activeItem.value?.id);

  function setActiveItemByValue(value: OptionValue) {
    activeItemValue.value = value;
  }

  function setActiveItemByIndex(index: number) {
    activeItemValue.value = normalizedItems.value[index]?.value ?? null;
  }

  function setNextActiveItem(amount = 1) {
    if (!activeItemValue.value) {
      setActiveItemByIndex(0);
      return;
    }
    const index = findItemIndex(activeItemValue.value, normalizedItems);
    if (index === null) {
      setActiveItemByIndex(0);
      return;
    }
    setActiveItemByIndex(
      Math.min(index + amount, normalizedItems.value.length - 1),
    );
  }

  function setPreviousActiveItem(amount = 1) {
    if (!activeItemValue.value) {
      setActiveItemByIndex(0);
      return;
    }
    const index = findItemIndex(activeItemValue.value, normalizedItems);
    if (index === null) {
      setActiveItemByIndex(0);
      return;
    }
    setActiveItemByIndex(Math.max(index - amount, 0));
  }

  function setFirstVisibleActive() {
    const firstVisibleSelected = normalizedItems.value.find((item) => {
      return arrayWrap(modelValue.value).includes(item.value);
    });
    if (firstVisibleSelected) {
      activeItemValue.value = firstVisibleSelected.value;
    } else {
      setActiveItemByIndex(0);
    }
  }

  return {
    activeItem,
    activeDescendantId,
    setActiveItemByValue,
    setActiveItemByIndex,
    setNextActiveItem,
    setPreviousActiveItem,
    setFirstVisibleActive,
  };
}

export { useActiveItem };
