<template>
  <div :class="[$style.container, { 's-input--invalid': invalid }]">
    <div v-if="label || tooltip" :class="$style.labelContainer">
      <SLabel
        v-if="label"
        :disabled
        :for="id"
        :pt="pt?.pcLabel"
        :required
        :show-required-type
        :size
      >
        {{ label }}
      </SLabel>
      <span
        v-if="tooltip"
        :id="ids.tooltip.value"
        v-tooltip.top="{
          value: tooltip,
          pt: assign({ text: { id: ids.tooltip.value } }, pt?.pcTooltip ?? {}),
        }"
        v-bind="pt?.pcTooltip?.icon"
        class="fas fa-circle-info"
        :class="$style.tooltip"
      />
    </div>
    <PMessage
      v-if="description"
      icon="fa-regular fa-circle-info"
      :pt="
        assign({ root: { id: ids.description.value } }, pt?.pcDescription ?? {})
      "
      :size="size"
    >
      {{ description }}
    </PMessage>
    <PIconField>
      <PInputIcon
        v-if="prefixIcon"
        :class="prefixIcon"
        :pt="pt?.pcPrefixIcon"
        :size
      />
      <slot
        :id="id"
        :described-by="describedBy"
        :error-id="errorId"
        :invalid="invalid"
        :listeners="validationListeners"
        name="input"
      />
      <PInputIcon
        v-if="suffixIcon"
        :class="suffixIcon"
        :pt="pt?.pcSuffixIcon"
        :size
      />
    </PIconField>
    <PMessage
      v-if="subtext"
      :pt="assign({ root: { id: ids.subtext.value } }, pt?.pcSubtext ?? {})"
      severity="gray"
      size="small"
      variant="simple"
    >
      {{ subtext }}
    </PMessage>
    <slot
      v-if="errorId"
      :id="errorId"
      :name="errorComponent!"
      v-bind="errorProps"
    >
      <PMessage
        :id="errorId"
        :pt="pt?.pcError"
        severity="error"
        size="small"
        variant="simple"
      >
        {{ error }}
      </PMessage>
    </slot>
  </div>
</template>

<script setup lang="ts" generic="ModelValue">
import { useValidationProvider } from "@solvari/common-fe/validation";
import { nanoid } from "nanoid";
import { assign } from "radash";
import { toRef } from "vue";

import type { BaseInputEmits, BaseInputProps } from "@/molecules/baseInput.ts";

import SLabel from "@/atoms/SLabel.vue";
import { useDescribedBy } from "@/helpers/useDescribedBy.ts";
import { useSuffixIcon } from "@/helpers/useSuffixIcon.ts";
import {
  PIconField,
  PInputIcon,
  PMessage,
  vTooltip,
} from "@/primeVueExports.ts";

const props = withDefaults(defineProps<BaseInputProps<ModelValue, object>>(), {
  showValidState: true,
  rules: () => [],
});

const emit = defineEmits<BaseInputEmits>();

const id = nanoid(10);

const { describedBy, ids } = useDescribedBy({
  tooltip: () => props.tooltip,
  description: () => props.description,
  subtext: () => props.subtext,
});

const {
  validationListeners,
  valid,
  invalid,
  error,
  errorId,
  errorProps,
  errorComponent,
  validating,
} = useValidationProvider(
  toRef(() => props.modelValue),
  props,
  emit,
);

const suffixIcon = useSuffixIcon({
  loading: () => props.loading,
  valid,
  invalid,
  validating,
  suffixIcon: () => props.suffixIcon,
  showValidState: () => props.showValidState,
});
</script>

<style module lang="postcss">
.container {
  @apply flex flex-col gap-2;
}

.labelContainer {
  @apply flex;
}

.tooltip {
  @apply ml-2;
}
</style>
