import { getMetadataFromArgus } from "@solvari/common-fe/argus/thick-client";
import { emitWindowRedirect, sLocalStorage } from "@solvari/common-fe/helpers";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import { v4 } from "uuid";
import { watchEffect } from "vue";

import { localeToMorpheus } from "@solvari/translations";

import type { ApplicationType, OriginSource } from "@/helpers/types";

import {
  directMatchLeadWithCustomer,
  postLead,
  type PostLeadReturn,
} from "@/api/lead.api";
import { usePusher } from "@/api/pusher";
import { redirectToUrl } from "@/helpers/redirectToUrl";
import { useI18nForms } from "@/plugins/i18n";
import { useFormStore } from "@/plugins/store/form";
import { useGtmStore } from "@/plugins/store/gtm";
import { useLeadStore } from "@/plugins/store/lead";

type RecentApplication = {
  formGroupId: number | null;
  formId: number;
  mySolvariUrl: string;
  /** @deprecated: replaced by mySolvariUrl and waitPageUrl */
  redirectUrl?: string;
  submittedAt: string;
  waitPageUrl: string;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface LocalStorage {
    recentApplications: RecentApplication[];
  }
}

type ApplicationTypeMap = {
  affiliate: { affiliateId: number; campaignId: number; iframeId: number };
  brand: { brandId: string };
  direct: { customerId: string };
  lead: { solvariAjax: true };
  partner: { solvariAjax: true };
};

type SubmitApplicationReturn = PostLeadReturn & {
  redirect: (url: string) => void;
};

const fetchStoredApplicationsFromLocalStorage = (): RecentApplication[] => {
  const storedApplications = sLocalStorage.getItem("recentApplications");

  if (!storedApplications) {
    return [];
  }

  // convert deprecated key
  return storedApplications.map((application: RecentApplication) => {
    if (!application.waitPageUrl || !application.mySolvariUrl) {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      application.waitPageUrl = application.redirectUrl ?? "";
      application.mySolvariUrl = application.waitPageUrl;
    }

    return application;
  });
};

/*
  Stores data about the application itself that the user doesn't control and doesn't change during filling
 */
const useApplicationStore = defineStore("application", {
  state: () => ({
    uuid: v4(),
    originLeadUuid: null as string | null,
    customerId: null as string | null,
    brandId: null as string | null,
    affiliateId: null as number | null,
    campaignId: null as number | null,
    iframeId: null as number | null,
    satelliteId: null as number | null,
    originSource: "SOLVARI" as OriginSource,
    applicationTypeProp: null as ApplicationType | null,
    submitPending: false,
    submitResult: null as SubmitApplicationReturn | null,
    submitError: null as Error | null,
  }),
  getters: {
    applicationType(): ApplicationType {
      if (this.applicationTypeProp) {
        return this.applicationTypeProp;
      }

      if (this.customerId) {
        return "DIRECT";
      }

      if (this.brandId) {
        return "BRAND";
      }

      if (this.affiliateId) {
        return "AFFILIATE";
      }

      return "LEAD";
    },
    isIframeApplicationType(): boolean {
      return ["AFFILIATE", "PARTNER"].includes(this.applicationType);
    },
    applicationTypeProps(
      state,
    ): ApplicationTypeMap[Lowercase<ApplicationType>] {
      if (this.applicationType === "DIRECT" && state.customerId) {
        return { customerId: state.customerId };
      }

      if (this.applicationType === "BRAND" && state.brandId) {
        return { brandId: state.brandId };
      }

      if (
        this.applicationType === "AFFILIATE" &&
        state.affiliateId &&
        state.campaignId &&
        state.iframeId
      ) {
        return {
          affiliateId: state.affiliateId,
          campaignId: state.campaignId,
          iframeId: state.iframeId,
        };
      }

      return { solvariAjax: true };
    },
    mostRecentApplication(): RecentApplication | null {
      const formStore = useFormStore();
      const storedApplications: RecentApplication[] =
        fetchStoredApplicationsFromLocalStorage();

      if (storedApplications.length === 0) {
        return null;
      }

      const matchingApplication = storedApplications.find((application) => {
        if (dayjs().diff(dayjs(application.submittedAt), "days") > 31) {
          return false;
        }

        const isSameFormId =
          !!formStore.formId && application.formId === formStore.formId;

        const isSameFormGroupId =
          !!formStore.formGroupId &&
          application.formGroupId === formStore.formGroupId;

        return isSameFormId || isSameFormGroupId;
      });

      return matchingApplication ?? null;
    },
  },
  actions: {
    async submitApplication(): Promise<SubmitApplicationReturn> {
      this.submitError = null;
      this.submitResult = null;

      const submitApplication = {
        direct: this.submitDirectApplication,
        affiliate: this.submitAffiliateApplication,
        partner: this.submitPartnerApplication,
        lead: this.submitLeadApplication,
        brand: this.submitLeadApplication,
      }[this.applicationType.toLowerCase() as Lowercase<ApplicationType>];

      const postApplication = useFormStore().isCrossSellForm
        ? this.postCrossSellApplication
        : this.postLeadApplication;

      const resolveSubmission = useFormStore().canCrossSell
        ? this.crossSell
        : this.redirect;

      try {
        const result = await submitApplication(postApplication);

        await useGtmStore().generateLeadEvent({
          isFirstApplication: result.isFirstApplication,
        });

        resolveSubmission(result);

        this.submitResult = result;
        return result;
      } catch (error) {
        this.submitError = error as Error;
        void useGtmStore().formErrorEvent({
          name: "submitButton",
          label: useI18nForms().tr("actions.submit"),
          error: useI18nForms().tr("error.title"),
        });
        throw error;
      }
    },
    async submitDirectApplication(
      postApplication: () => Promise<PostLeadReturn>,
    ): Promise<SubmitApplicationReturn> {
      const directMatchPromise = new Promise<{
        mySolvariUrl: string;
        waitPageUrl: string;
      }>((resolve) => {
        const resolveMatch = async () => {
          resolve(
            await directMatchLeadWithCustomer(this.uuid, this.customerId!),
          );
        };

        usePusher()
          .subscribe(`application.${this.uuid}`)
          .bind("application.created", resolveMatch);
      });
      const submitResult = await postApplication();
      const { mySolvariUrl, waitPageUrl } = await directMatchPromise;
      return {
        ...submitResult,
        redirect: redirectToUrl,
        waitPageUrl,
        mySolvariUrl,
      };
    },
    async submitAffiliateApplication(
      postApplication: () => Promise<PostLeadReturn>,
    ): Promise<SubmitApplicationReturn> {
      const result = await postApplication();

      return {
        ...result,
        redirect: result.shouldRedirectParent
          ? emitWindowRedirect
          : redirectToUrl,
      };
    },
    async submitPartnerApplication(
      postApplication: () => Promise<PostLeadReturn>,
    ): Promise<SubmitApplicationReturn> {
      const result = await postApplication();
      return {
        ...result,
        redirect: emitWindowRedirect,
      };
    },
    async submitLeadApplication(
      postApplication: () => Promise<PostLeadReturn>,
    ): Promise<SubmitApplicationReturn> {
      const result = await postApplication();
      return {
        ...result,
        redirect: redirectToUrl,
      };
    },
    async getSubmitData() {
      const lead = useLeadStore().getLead;

      const application = {
        uuid: this.uuid,
        brandId: this.brandId,
        satelliteId: this.satelliteId,
        originSource: this.originSource,
        ...this.applicationTypeProps,
      };

      const formStore = useFormStore();
      const form = {
        formId: formStore.formId,
        revisionId: formStore.revisionId,
        localeCode: localeToMorpheus(formStore.locale!),
      };

      const metadata = await getMetadataFromArgus();

      return {
        ...lead,
        ...application,
        ...form,
        ...metadata,
      };
    },
    async postLeadApplication() {
      const result = await postLead(await this.getSubmitData());
      this.storeRecentApplication(result.waitPageUrl, result.mySolvariUrl);

      return result;
    },
    async postCrossSellApplication() {
      return await postLead({
        ...(await this.getSubmitData()),
        originLeadUuid: this.originLeadUuid!,
      });
    },
    crossSell() {
      if (!this.originLeadUuid) {
        this.originLeadUuid = this.uuid;
      }
      this.uuid = v4();
      useFormStore().goToCrossSellForm();
      this.submitPending = false;
    },
    redirect(result: SubmitApplicationReturn) {
      result.redirect(result.waitPageUrl);
    },
    storeRecentApplication(waitPageUrl: string, mySolvariUrl: string) {
      const formStore = useFormStore();
      const storedApplications: RecentApplication[] =
        fetchStoredApplicationsFromLocalStorage();

      const nonOutdatedApplications = storedApplications.filter(
        ({ submittedAt }: RecentApplication) => {
          return dayjs().diff(dayjs(submittedAt), "days") > 31;
        },
      );

      sLocalStorage.setItem("recentApplications", [
        {
          formId: formStore.formId,
          formGroupId: formStore.formGroupId,
          waitPageUrl,
          mySolvariUrl,
          submittedAt: dayjs().format(),
        },
        ...nonOutdatedApplications,
      ]);
    },
    initStoreWatchers(
      props: Readonly<{
        affiliateId: number | null;
        applicationType: ApplicationType | null;
        brandId: string | null;
        campaignId: number | null;
        customerId: string | null;
        iframeId: number | null;
        originSource: OriginSource;
        satelliteId: number | null;
      }>,
    ) {
      watchEffect(() => (this.customerId = props.customerId));
      watchEffect(() => (this.brandId = props.brandId));
      watchEffect(() => (this.satelliteId = props.satelliteId));
      watchEffect(() => (this.affiliateId = props.affiliateId));
      watchEffect(() => (this.campaignId = props.campaignId));
      watchEffect(() => (this.iframeId = props.iframeId));
      watchEffect(() => (this.originSource = props.originSource));
      watchEffect(() => (this.applicationTypeProp = props.applicationType));
    },
  },
});

export type { ApplicationTypeMap };
export { useApplicationStore };
