import type { Ref } from "vue";

import { computed } from "vue";

import { useI18n } from "@solvari/translations";

/*
  Auto translation structure:
  fields: {
    fieldName: {
      label: string,
      tooltip: string,
      description: string,
      placeholder: string,
      subtext: string,
      errorLabel: string,
      validation: {
        [ruleName: string]: string,
      }
    }
  }
*/

function useAutoI18n(
  name: Readonly<Ref<string>>,
  props: Partial<{
    description: string | null;
    errorLabel: string | null;
    label: string | null;
    placeholder: string | null;
    subtext: string | null;
    tooltip: string | null;
  }>,
) {
  const { trOptional } = useI18n();

  const label = computed(
    () => props.label ?? trOptional(`fields.${name.value}.label`) ?? name.value,
  );

  const tooltip = computed(
    () => props.tooltip ?? trOptional(`fields.${name.value}.tooltip`),
  );

  const description = computed(
    () => props.description ?? trOptional(`fields.${name.value}.description`),
  );

  const placeholder = computed(
    () => props.placeholder ?? trOptional(`fields.${name.value}.placeholder`),
  );

  const subtext = computed(
    () => props.subtext ?? trOptional(`fields.${name.value}.subtext`),
  );

  const errorLabel = computed(
    () =>
      props.errorLabel ??
      trOptional(`fields.${name.value}.errorLabel`) ??
      label.value,
  );

  return {
    label,
    tooltip,
    description,
    placeholder,
    subtext,
    errorLabel,
  };
}

export { useAutoI18n };
