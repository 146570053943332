import { isString } from "radash";

import { useI18nCommonFe } from "@solvari/translations";
import { arrayWrap } from "@solvari/utils";

import type { PrimitiveOrArrayValue } from "@/lib/components/logic/atoms/input/props";

import { eager } from "@/lib/validation/events";
import { defineRule } from "@/lib/validation/rules/defineRule";

const required = defineRule({
  name: "required",
  validate: (value: unknown) => {
    if (Array.isArray(value)) {
      return !!value.length;
    }
    if (typeof value === "number") {
      return true;
    }
    return !!value;
  },
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.required", messageParams),
});

const minLength = defineRule({
  name: "minLength",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: number | string,
  ) => {
    if (!value) {
      return params === 0;
    }
    if (Array.isArray(value)) {
      return value.every(
        (singleValue: boolean | number | string) =>
          singleValue.toString().length >= Number(params),
      );
    }
    return value.toString().length >= Number(params);
  },
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.min_length", messageParams),
});

const maxLength = defineRule({
  name: "maxLength",
  validate: (
    value: PrimitiveOrArrayValue | null | undefined,
    params: number | string,
  ) => {
    if (!value) {
      return true;
    }
    if (Array.isArray(value)) {
      return value.every(
        (singleValue: boolean | number | string) =>
          singleValue.toString().length <= Number(params),
      );
    }
    return value.toString().length <= Number(params);
  },
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.max_length", messageParams),
});

const telephone = defineRule({
  name: "telephone",
  validate: (phone: PrimitiveOrArrayValue | null | undefined) => {
    if (!phone) {
      return false;
    }

    return arrayWrap(phone).every((phone) => {
      if (!isString(phone) || /[^\d()+\-/\s]/.test(phone)) {
        return false;
      }

      const phoneDigits = phone.replace(/\D/g, "");

      return !(phoneDigits.length < 9 || phoneDigits.length > 14);
    });
  },
  events: eager,
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.telephone", messageParams),
});

const email = defineRule({
  name: "email",
  validate: (value: PrimitiveOrArrayValue | null | undefined) => {
    if (!value) {
      return false;
    }

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return arrayWrap(value).every(
      (value) => typeof value === "string" && emailRegex.test(value),
    );
  },
  events: eager,
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.email", messageParams),
});

const patternRule = defineRule({
  name: "pattern",
  validate: (value: unknown, regex: (RegExp | string)[] | RegExp | string) => {
    if (
      !(
        typeof value === "string" ||
        typeof value === "number" ||
        Array.isArray(value)
      ) ||
      !value
    ) {
      return false;
    }

    const mappedRegex = arrayWrap(regex).map((singleRegex: RegExp | string) => {
      if (typeof singleRegex === "string") {
        return new RegExp(singleRegex);
      }
      return singleRegex;
    });

    return arrayWrap(value).every((singleValue) => {
      return mappedRegex.some((singleMappedRegex) =>
        singleMappedRegex.test(String(singleValue)),
      );
    });
  },
  message: ({ messageParams }) =>
    useI18nCommonFe().tr("common_fe.validation.pattern", messageParams),
});

export { email, maxLength, minLength, patternRule, required, telephone };
