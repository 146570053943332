import type { PropType } from "vue";

import { toReactive } from "@vueuse/core";
import { nanoid } from "nanoid";
import { computed, nextTick, reactive, ref, toRef, watch } from "vue";

import type { DefineProps } from "@/lib/composables/componentComposable";
import type { SFile, SFileLocal } from "@/lib/composables/useFileUpload";
import type { ValidationRuleBase } from "@/lib/validation/validation.types";
import type { UseValidationProviderEmits } from "@/lib/validation/ValidationProvider/useValidationProvider";

import * as useDescription from "@/lib/components/logic/atoms/useDescription";
import * as useLabel from "@/lib/components/logic/atoms/useLabel";
import * as usePreview from "@/lib/components/logic/atoms/usePreview";
import * as useSubtext from "@/lib/components/logic/atoms/useSubtext";
import * as useTooltip from "@/lib/components/logic/atoms/useTooltip";
import * as useUpload from "@/lib/components/logic/atoms/useUpload";
import {
  emitsDefinition,
  pickProps,
  propsDefinition,
} from "@/lib/composables/componentComposable";
import { useAutoI18n } from "@/lib/composables/useAutoI18n.ts";
import { useDescribedBy } from "@/lib/composables/useDescribedBy";
import { useModel } from "@/lib/composables/useModel";
import {
  mergeListeners,
  mergeReactive,
  reactivePick,
} from "@/lib/helpers/reactivity";
import { acceptRule, maxSizeRule } from "@/lib/validation/rules/file";
import {
  useValidationProvider,
  useValidationProviderEmits,
  useValidationProviderScoped,
} from "@/lib/validation/ValidationProvider/useValidationProvider";

const props = propsDefinition({
  ...useValidationProviderScoped<(SFile | SFileLocal)[]>(),
  ...useLabel.scoped,
  ...useDescription.scoped,
  ...useTooltip.scoped,
  ...useUpload.scoped,
  ...useSubtext.scoped,
  showRequiredType: {
    type: String as PropType<"none" | "optional" | "required">,
    default: "optional",
  },
  name: { type: String, required: true, default: () => nanoid(10) },
  modelValue: {
    type: Array as PropType<(SFile | SFileLocal)[]>,
    default: () => [],
  },
  accept: { type: String, required: false },
  maxSize: { type: [String, Number], required: false },
});

const emits = emitsDefinition([
  "update:modelValue",
  ...useValidationProviderEmits,
]);

type UseFileInputProps = DefineProps<typeof props>;
type UseFileInputEmit = UseValidationProviderEmits &
  ((
    event: "update:modelValue",
    value: UseFileInputProps["modelValue"],
  ) => void);

function use(props: UseFileInputProps, emit: UseFileInputEmit) {
  const modelValue = useModel("modelValue", props, emit, { local: true });
  const validationModelValue = ref(modelValue.value);
  watch(modelValue, () => (validationModelValue.value = modelValue.value), {
    deep: true,
  });

  const { label, tooltip, description, subtext, errorLabel } = useAutoI18n(
    toRef(() => props.name),
    reactivePick(props, [
      "label",
      "tooltip",
      "description",
      "subtext",
      "errorLabel",
    ]),
  );

  // Ids
  const id = nanoid(10);
  const { describedBy, ids } = useDescribedBy(
    reactive({ tooltip, description, subtext }),
  );

  // Validation
  const inferredRules = computed(() => {
    const rules = [...props.rules];

    if (props.accept) {
      rules.unshift(acceptRule(props.accept));
    }

    if (props.maxSize) {
      rules.unshift(maxSizeRule(Number(props.maxSize)));
    }

    return rules;
  });

  const {
    validationListeners,
    error,
    errorComponent,
    errorProps,
    validateEvent,
    invalid,
  } = useValidationProvider(
    validationModelValue,
    mergeReactive(props, {
      rules: inferredRules,
      errorLabel,
    }),
    emit,
  );

  async function rejectInvalid(files: (SFile | SFileLocal)[]) {
    validationModelValue.value = files;
    await validateEvent("fileUpload");

    if (invalid.value) {
      await nextTick(); // Remove files AFTER triggering validation, to show the message but still reject files
      const rules = inferredRules.value.filter(
        (
          rule,
        ): rule is ValidationRuleBase<
          (SFile | SFileLocal)[] | SFile | SFileLocal,
          unknown,
          boolean
        > => ["maxSize", "accept"].includes(rule.name),
      );
      validationModelValue.value = files.filter((file) => {
        return rules.every((rule) => rule.validate(file));
      });
    }

    return validationModelValue.value;
  }

  async function onUploadInput(files: (SFile | SFileLocal)[]) {
    modelValue.value = await rejectInvalid(files);
  }

  function removeFile(removedUuid: string) {
    modelValue.value = modelValue.value.filter(
      (file) => file.uuid !== removedUuid,
    );
  }

  /*
    Template
   */

  const labelAtom = {
    props: mergeReactive(pickProps(props, useLabel.scoped), { for: id, label }),
  };

  const tooltipAtom = {
    props: mergeReactive(pickProps(props, useTooltip.scoped), {
      tooltipId: toRef(() => ids.tooltip),
      tooltip,
    }),
  };

  const descriptionAtom = {
    if: useDescription.vIf(reactive({ description })),
    props: mergeReactive(pickProps(props, useDescription.scoped), {
      descriptionId: toRef(() => ids.description),
      description,
    }),
  };

  const uploadAtom = {
    props: mergeReactive(pickProps(props, useUpload.scoped), {
      id,
      invalid,
      modelValue: validationModelValue,
      describedBy,
    }),
    on: mergeListeners(
      { "update:modelValue": onUploadInput },
      toReactive(validationListeners),
    ),
  };

  const previewAtom = {
    props: mergeReactive(pickProps(props, usePreview.scoped), {
      files: modelValue,
    }),
    on: { removedFile: removeFile },
  };

  const subtextAtom = {
    if: useSubtext.vIf(reactive({ subtext })),
    props: mergeReactive(pickProps(props, useSubtext.scoped), {
      subtext,
      subtextId: toRef(() => ids.subtext),
    }),
  };

  return {
    labelAtom,
    tooltipAtom,
    descriptionAtom,
    uploadAtom,
    previewAtom,
    subtextAtom,
    errorProps,
    errorComponent,
    error,
  };
}

export type { UseFileInputEmit, UseFileInputProps };
export { emits, props, use };
