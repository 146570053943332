<template>
  <SButtonGroup
    v-model="model"
    class="form-question"
    :items="answers"
    name="gender"
    required
    show-required-type="none"
    v-on="reEmit(emit, ['focus', 'validationError'])"
  />
</template>

<script setup lang="ts">
import { reEmit } from "@solvari/common-fe/helpers";
import { computed, toRef } from "vue";

import { SButtonGroup } from "@solvari/common-fe";
import { useI18n } from "@solvari/translations";

import { useLeadStore } from "@/plugins/store/lead";
defineOptions({ inheritAttrs: false });

const emit = defineEmits<{
  (
    event: "validationError",
    value: { error: string | null; name: string },
  ): void;
  (event: "focus", name: string): void;
}>();

const leadStore = useLeadStore();
const { tr } = useI18n();

const answers = computed(() => [
  { label: tr(`fields.mr`), value: "mr" },
  { label: tr(`fields.mrs`), value: "mrs" },
]);

const model = toRef(leadStore, "title");
</script>
