import type { Metadata } from "@solvari/common-fe/argus/thick-client";

import ky from "ky";
import { serialize } from "object-to-formdata";
import { objectToSnake } from "ts-case-convert";

import type { LocaleMorpheus } from "@solvari/translations";
import { getEnv } from "@solvari/utils";

import type { OriginSource } from "@/helpers/types";
import type { ApplicationTypeMap } from "@/plugins/store/application";

type SubmitBaseData = Metadata &
  SubmitApplicationData & {
    formId: number;
    localeCode: LocaleMorpheus;
    revisionId: number;
  };

type SubmitApplicationData = ApplicationTypeMap[keyof ApplicationTypeMap] & {
  brandId: string | null;
  originSource: OriginSource;
  satelliteId: number | null;
  uuid: string;
};

type SubmitAdditionalData = {
  additional: Record<number, File[] | number[] | string[] | number | string>;
};

type SubmitBaseQuestionData = {
  city: string;
  description: string;
  email: string;
  firstName: string;
  houseNr: string;
  lastName: string;
  newsletter: boolean;
  phone: string;
  productId: number;
  street: string;
  title: "mr" | "mrs";
  whatsappAgreement: boolean;
  zipCode: string;
};

type PostLeadReturn = {
  applicationType?: keyof ApplicationTypeMap;
  isFirstApplication: boolean;
  mySolvariUrl: string;
  shouldRedirectParent?: boolean;
  waitPageUrl: string;
};

async function postLead(
  data: SubmitAdditionalData &
    SubmitBaseData &
    (SubmitBaseQuestionData | { originLeadUuid: string }),
) {
  const url = `${getEnv().network.api}/v3/lead/submission`;

  const formData = serialize({
    ...objectToSnake({
      ...data,
      formVersion: import.meta.env.npm_package_version as string,
    }),
    experiments: data.experiments,
    additional: data.additional,
  });

  return await ky
    .post(url, { body: formData, retry: 10, timeout: 30000 })
    .json<PostLeadReturn>();
}

async function directMatchLeadWithCustomer(guid: string, customerId: string) {
  const url = `${
    getEnv().network.api
  }/system/v2/lead/direct-match-customer/${guid}/${customerId}`;

  return await ky
    .post(url, { retry: 10, timeout: 30000 })
    .json<{ mySolvariUrl: string; waitPageUrl: string }>();
}

export { directMatchLeadWithCustomer, postLead };
export type { PostLeadReturn };
